import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc, arrayUnion } from 'firebase/firestore';
import { Box, Button, FormControl, FormLabel, Input, Heading, VStack, Image, Select, Text } from '@chakra-ui/react';
import { auth, db } from '../Firebase';
import logo from '../assets/whitelogo.svg';

function Onboarding() {
  const [organizationId, setOrganizationId] = useState('');
  const [role, setRole] = useState('');
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [isOrganizationValid, setIsOrganizationValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        if (organizationId) {
          const organizationDocRef = doc(db, 'org', organizationId);
          const organizationDocSnapshot = await getDoc(organizationDocRef);
          if (organizationDocSnapshot.exists()) {
            const organizationData = organizationDocSnapshot.data();
            setDepartments(organizationData.departments || []);
            setIsOrganizationValid(true);
            setErrorMessage('');
          } else {
            setDepartments([]);
            setIsOrganizationValid(false);
            setErrorMessage('Invalid organization ID. Please provide a valid organization ID.');
          }
        } else {
          setDepartments([]);
          setIsOrganizationValid(false);
          setErrorMessage('');
        }
      } catch (error) {
        console.error('Error fetching departments:', error);
      }
    };

    fetchDepartments();
  }, [organizationId]);

  const handleContinue = async () => {
    try {
      // Check if the organization ID exists in the database
      const organizationDocRef = doc(db, 'org', organizationId);
      const organizationDocSnapshot = await getDoc(organizationDocRef);
      if (organizationDocSnapshot.exists()) {
        // Update the user document with the selected role, organization ID, and department (if available)
        await updateDoc(doc(db, 'users', auth.currentUser.uid), {
          organizationId,
        });

        // Add the user to the staff array in the organization document
        await updateDoc(organizationDocRef, {
          staff: arrayUnion({
            userId: auth.currentUser.uid,
            role,
            department: selectedDepartment || '',
            verified: false,
          }),
        });

        // Navigate to the dashboard
        navigate('/dashboard');
      } else {
        // Organization ID does not exist, handle the error
        console.error('Invalid organization ID');
        // Display an error message to the user
        setErrorMessage('Invalid organization ID. Please provide a valid organization ID.');
      }
    } catch (error) {
      console.error('Error updating user and organization documents:', error);
      // Handle the error, e.g., display an error message
    }
  };

  return (
    <Box bg="gray.100" minH="100vh" display="flex" alignItems="center" justifyContent="center">
      <Box w="500px" p={8} borderWidth={1} borderRadius={8} boxShadow="lg" bg="white">
        <Box mb={6} textAlign="center">
          <Box display="flex" justifyContent="center" mb={10}>
            <Image src={logo} alt="White Logo" w="300px" />
          </Box>
          <Heading>Onboarding</Heading>
        </Box>
        <VStack spacing={4}>
          <FormControl>
            <FormLabel>Organization ID</FormLabel>
            <Input
              type="text"
              value={organizationId}
              onChange={(e) => setOrganizationId(e.target.value)}
              placeholder="Enter your organization ID"
            />
          </FormControl>
          {errorMessage && (
            <Text color="red.500">{errorMessage}</Text>
          )}
          {isOrganizationValid && (
            <>
              {departments.length > 0 && (
                <FormControl>
                  <FormLabel>Department</FormLabel>
                  <Select
                    value={selectedDepartment}
                    onChange={(e) => setSelectedDepartment(e.target.value)}
                    placeholder="Select your department"
                  >
                    {departments.map((department, index) => (
                      <option key={index} value={department}>
                        {department}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              )}
              <FormControl>
                <FormLabel>Role</FormLabel>
                <Select
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  placeholder="Select your role"
                >
                  <option value="admin">Admin</option>
                  <option value="trainer">Trainer</option>
                  <option value="trainee">Trainee</option>
                </Select>
              </FormControl>
              <Button
                onClick={handleContinue}
                colorScheme="blue"
                size="lg"
                width="full"
                isDisabled={!role}
              >
                Join Organization
              </Button>
            </>
          )}
        </VStack>
      </Box>
    </Box>
  );
}

export default Onboarding;
