import React from "react";
import {
  Box,
  Heading,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import StaffBreakdown from "./StaffBreakdown";
import AllStaff from "./StaffAll";
import IncomingRequests from "./StaffIncoming";

function StaffMain() {
  return (
    <Box>
      <Heading as="h2" size="xl" mb={4}>
        Staff
      </Heading>
      <Tabs>
        <TabList>
          <Tab>Staff Breakdown</Tab>
          <Tab>All Staff</Tab>
          <Tab>Incoming Requests</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <StaffBreakdown />
          </TabPanel>
          <TabPanel>
            <AllStaff />
          </TabPanel>
          <TabPanel>
            <IncomingRequests />
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Outlet />
    </Box>
  );
}

export default StaffMain;
