import React, { useEffect, useState } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Heading,
  Text,
  VStack,
  HStack,
  Badge,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Wrap,
  WrapItem,
  Tag,
  Stat,
  StatLabel,
  StatNumber,
  StatGroup,
  Divider,
  Spacer,
  Link
} from '@chakra-ui/react';
import { doc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { db } from "../../../Firebase";
import { useAuth } from "../../../contexts/AuthContext";
import ReportPDF from './ReportPDF';
import * as IconsFA from 'react-icons/fa';

const ReportDetails = () => {
  const { reportId } = useParams();
  const { userDetails } = useAuth();
  const [report, setReport] = useState(null);
  const [loading, setLoading] = useState(true);
  const [overallResult, setOverallResult] = useState(null);
  const [earnedPoints, setEarnedPoints] = useState(0);
  const [passedCount, setPassedCount] = useState(0);

  useEffect(() => {
    const fetchReport = async () => {
      if (userDetails?.organizationId && reportId) {
        try {
          const reportRef = doc(db, 'org', userDetails.organizationId, 'reports', reportId);
          const reportSnap = await getDoc(reportRef);
          
          if (reportSnap.exists()) {
            const reportData = {id: reportSnap.id, ...reportSnap.data()};
            setReport(reportData);
            evaluateOverallResult(reportData);
          } else {
            console.log("No such document!");
          }
        } catch (error) {
          console.error("Error fetching report:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchReport();
  }, [reportId, userDetails]);

  const evaluateOverallResult = (reportData) => {
    let passFailThresholdMet = true;
    let totalPointsThresholdMet = true;
    let totalEarnedPoints = 0;
    let totalPassedCount = 0;

    const passFailQuestions = reportData.formResponses.filter(q => q.answerDetails && q.answerDetails.result);
    const passedQuestions = passFailQuestions.filter(q => q.answerDetails.result === 'pass');
    totalPassedCount = passedQuestions.length;
    if (passFailQuestions.length > 0 && totalPassedCount < reportData.totalPassFailCount) {
      passFailThresholdMet = false;
    }

    totalEarnedPoints = reportData.formResponses.reduce((sum, q) => {
      return sum + (q.answerDetails && q.answerDetails.points ? q.answerDetails.points : 0);
    }, 0);
    if (totalEarnedPoints < reportData.totalFormPoints) {
      totalPointsThresholdMet = false;
    }

    setOverallResult(passFailThresholdMet && totalPointsThresholdMet ? 'PASS' : 'FAIL');
    setEarnedPoints(totalEarnedPoints);
    setPassedCount(totalPassedCount);
  };

  const formatDate = (date) => {
    if (date instanceof Date) {
      return date.toLocaleString();
    } else if (typeof date === 'string') {
      return new Date(date).toLocaleString();
    } else if (date && date.seconds) {
      return new Date(date.seconds * 1000).toLocaleString();
    }
    return 'Invalid Date';
  };

  const handleCompleteReport = async () => {
    if (report && userDetails) {
      try {
        const reportRef = doc(db, 'org', userDetails.organizationId, 'reports', report.id);
        await updateDoc(reportRef, {
          status: 'verified',
          verifiedOn: serverTimestamp()
        });
        setReport({...report, status: 'verified', verifiedOn: new Date()});
      } catch (error) {
        console.error("Error updating report status:", error);
      }
    }
  };

  if (loading) {
    return <Text>Loading report details...</Text>;
  }

  if (!report) {
    return <Text>Report not found.</Text>;
  }

  const isSubmittedForCurrentUser = report.submittedFor.id === userDetails.uid;
  const showCompleteButton = isSubmittedForCurrentUser && report.status !== 'verified';

  const IconComponent = IconsFA[report.evaluationIcon] || IconsFA.FaQuestionCircle;

  return (
    <Box>
      <HStack spacing={4} mb={4}>
        <IconComponent size={40} />
        <VStack align="start" spacing={0}>
          <Heading size="lg">{report.evaluationName}</Heading>
          <Text fontSize="sm" color="gray.600">{report.evaluationDescription}</Text>
        </VStack>
        <Spacer />
        <ReportPDF report={report} formatDate={formatDate} />
        <Box w={6}></Box>
      </HStack>
      {report.evaluationTags && (
        <Wrap spacing={2} mb={4}>
          {report.evaluationTags.map((tag, index) => (
            <WrapItem key={index}>
              <Tag>{tag}</Tag>
            </WrapItem>
          ))}
        </Wrap>
      )}
      <VStack align="stretch" spacing={4}>
        <HStack justify="space-between">
          <Badge colorScheme={report.status === 'verified' ? 'green' : 'orange'} fontSize="sm" p={2}>
            Status: {report.status || 'Pending'}
          </Badge>
          <Text fontWeight="bold">Submitted At: {formatDate(report.timeSubmitted)}</Text>
        </HStack>
        <Text>
          <strong>Submitted By:</strong>{' '}
          <Link as={RouterLink} to={`/dashboard/staff/${report.submittedBy.id}`} color="blue.500">
            {report.submittedBy.name}
          </Link>
        </Text>
        <Text>
          <strong>Submitted For:</strong>{' '}
          <Link as={RouterLink} to={`/dashboard/staff/${report.submittedFor.id}`} color="blue.500">
            {report.submittedFor.name}
          </Link>
        </Text>
        {report.verifiedOn && (
          <Text><strong>Verified On:</strong> {formatDate(report.verifiedOn)}</Text>
        )}
        
        <Divider />
        
        <StatGroup>
          <Stat>
            <StatLabel>Total Points</StatLabel>
            <StatNumber>{earnedPoints} / {report.totalFormPoints}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Pass/Fail Count</StatLabel>
            <StatNumber>{passedCount} / {report.totalPassFailCount}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Overall Result</StatLabel>
            <StatNumber>
              <Badge colorScheme={overallResult === 'PASS' ? 'green' : 'red'} fontSize="lg" p={2}>
                {overallResult}
              </Badge>
            </StatNumber>
          </Stat>
        </StatGroup>

        <Divider />
        
        <Heading size="md" mt={4}>Form Responses</Heading>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Question</Th>
              <Th>Answer</Th>
              <Th>Result</Th>
            </Tr>
          </Thead>
          <Tbody>
            {report.formResponses.map((response, index) => (
              <Tr key={index}>
                <Td>{response.label}</Td>
                <Td>{Array.isArray(response.answer) ? response.answer.join(', ') : response.answer}</Td>
                <Td>
                  {response.answerDetails && response.answerDetails.result && (
                    <Badge colorScheme={response.answerDetails.result === 'pass' ? 'green' : 'red'}>
                      {response.answerDetails.result.toUpperCase()}
                    </Badge>
                  )}
                  {response.answerDetails && response.answerDetails.points !== undefined && (
                    <Text>{response.answerDetails.points} / {response.answerDetails.maxPoints} points</Text>
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>

        {showCompleteButton && (
          <Button colorScheme="green" onClick={handleCompleteReport}>
            Complete Report
          </Button>
        )}
      </VStack>
    </Box>
  );
};

export default ReportDetails;