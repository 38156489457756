import React, { useState, useEffect } from "react";
import { doc, getDoc, onSnapshot, updateDoc } from "firebase/firestore";
import {
  Box,
  Heading,
  Grid,
  GridItem,
  Avatar,
  Text,
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Checkbox,
  CheckboxGroup,
  Input,
  InputGroup,
  InputLeftElement,
  Icon,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { db, auth } from "../../../Firebase";

function StaffBreakdown() {
  const [trainers, setTrainers] = useState([]);
  const [trainees, setTrainees] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTrainer, setSelectedTrainer] = useState(null);
  const [selectedTrainees, setSelectedTrainees] = useState([]);
  const [organizationId, setOrganizationId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const fetchStaffMembers = async () => {
          try {
            const userDocRef = doc(db, "users", user.uid);
            const userDocSnapshot = await getDoc(userDocRef);
            if (userDocSnapshot.exists()) {
              const userData = userDocSnapshot.data();
              const organizationId = userData.organizationId;
              if (organizationId) {
                setOrganizationId(organizationId);
                const organizationDocRef = doc(db, "org", organizationId);
                const unsubscribeOrg = onSnapshot(organizationDocRef, async (organizationDocSnapshot) => {
                  if (organizationDocSnapshot.exists()) {
                    const organizationData = organizationDocSnapshot.data();
                    const staff = organizationData.staff || [];

                    const staffWithDetails = await Promise.all(
                      staff.map(async (member) => {
                        const staffDocRef = doc(db, "users", member.userId);
                        const staffDocSnapshot = await getDoc(staffDocRef);
                        if (staffDocSnapshot.exists()) {
                          const staffData = staffDocSnapshot.data();
                          return {
                            ...member,
                            firstName: staffData.firstName,
                            lastName: staffData.lastName,
                            profilePictureURL: staffData.profilePictureURL,
                            trainees: member.trainees || [],
                          };
                        } else {
                          return member;
                        }
                      })
                    );

                    const trainersData = staffWithDetails.filter((member) => member.role === "trainer");
                    const traineesData = staffWithDetails.filter((member) => member.role === "trainee");

                    setTrainers(trainersData);
                    setTrainees(traineesData);
                  } else {
                    console.error("Organization document not found");
                  }
                });

                return unsubscribeOrg;
              } else {
                console.error("User document does not contain organizationId");
              }
            } else {
              console.error("User document not found");
            }
          } catch (error) {
            console.error("Error fetching staff members:", error);
          }
        };

        const unsubscribeOrg = await fetchStaffMembers();

        return () => {
          if (unsubscribeOrg) {
            unsubscribeOrg();
          }
        };
      }
    });

    return () => unsubscribe();
  }, []);

  const handleTrainerClick = (trainer) => {
    setSelectedTrainer(trainer);
    setSelectedTrainees(trainer.trainees.map((trainee) => trainee.userId));
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedTrainer(null);
    setSelectedTrainees([]);
    setIsOpen(false);
  };

  const handleTraineeSelection = (traineeIds) => {
    setSelectedTrainees(traineeIds);
  };

  const handleAssignTrainees = async () => {
    if (!selectedTrainer || !organizationId) return;

    try {
      const organizationDocRef = doc(db, "org", organizationId);
      const organizationDocSnapshot = await getDoc(organizationDocRef);
      if (organizationDocSnapshot.exists()) {
        const organizationData = organizationDocSnapshot.data();
        const updatedStaff = organizationData.staff.map((member) => {
          if (member.userId === selectedTrainer.userId) {
            return {
              ...member,
              trainees: selectedTrainees.map((traineeId) => ({ userId: traineeId })),
            };
          } else if (selectedTrainees.includes(member.userId)) {
            const updatedTrainers = member.trainers || [];
            if (!updatedTrainers.includes(selectedTrainer.userId)) {
              updatedTrainers.push(selectedTrainer.userId);
            }
            return {
              ...member,
              trainers: updatedTrainers,
            };
          } else if (member.role === "trainee" && member.trainers && member.trainers.includes(selectedTrainer.userId)) {
            return {
              ...member,
              trainers: member.trainers.filter((trainerId) => trainerId !== selectedTrainer.userId),
            };
          }
          return member;
        });

        await updateDoc(organizationDocRef, {
          staff: updatedStaff,
        });

        setTrainers((prevTrainers) =>
          prevTrainers.map((trainer) =>
            trainer.userId === selectedTrainer.userId
              ? { ...trainer, trainees: selectedTrainees.map((traineeId) => ({ userId: traineeId })) }
              : trainer
          )
        );

        handleCloseModal();
      } else {
        console.error("Organization document not found");
      }
    } catch (error) {
      console.error("Error assigning trainees:", error);
    }
  };

  const filteredTrainees = trainees.filter(
    (trainee) =>
      trainee.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      trainee.lastName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box>
      <Heading as="h3" size="lg" mb={8}>
        Staff Breakdown
      </Heading>
      <Box mb={8}>
        <Heading as="h3" size="lg" mb={4}>
          Trainers
        </Heading>
        <Grid templateColumns="repeat(auto-fill, minmax(300px, 1fr))" gap={6}>
          {trainers.map((trainer) => (
            <GridItem key={trainer.userId}>
              <Flex
                alignItems="center"
                p={6}
                bg="gray.200"
                borderRadius="lg"
                boxShadow="md"
                flexDirection="column"
                textAlign="center"
              >
                <Avatar
                  size="xl"
                  src={trainer.profilePictureURL}
                  name={`${trainer.firstName} ${trainer.lastName}`}
                  mb={4}
                />
                <Text fontWeight="bold" fontSize="xl" mb={2}>
                  {trainer.firstName} {trainer.lastName}
                </Text>
                <Button colorScheme="blue" onClick={() => handleTrainerClick(trainer)}>
                  Assign Trainees
                </Button>
                {trainer.trainees.length > 0 && (
                  <Box mt={4}>
                    <Text fontWeight="bold" mb={2}>
                      Trainees:
                    </Text>
                    <Flex flexDirection="column" alignItems="center">
                      {trainer.trainees.map((trainee) => {
                        const traineeDetails = trainees.find((t) => t.userId === trainee.userId);
                        return traineeDetails ? (
                          <Flex key={trainee.userId} alignItems="center" mb={2}>
                            <Avatar
                              size="sm"
                              src={traineeDetails.profilePictureURL}
                              name={`${traineeDetails.firstName} ${traineeDetails.lastName}`}
                              mr={2}
                            />
                            <Text>
                              {traineeDetails.firstName} {traineeDetails.lastName}
                            </Text>
                          </Flex>
                        ) : null;
                      })}
                    </Flex>
                  </Box>
                )}
              </Flex>
            </GridItem>
          ))}
        </Grid>
      </Box>

      <Modal isOpen={isOpen} onClose={handleCloseModal} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Assign Trainees for{" "}
            {selectedTrainer ? `${selectedTrainer.firstName} ${selectedTrainer.lastName}` : ""}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <InputGroup mb={4}>
              <InputLeftElement pointerEvents="none">
                <Icon as={SearchIcon} color="gray.300" />
              </InputLeftElement>
              <Input
                placeholder="Search trainees"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </InputGroup>
            <CheckboxGroup value={selectedTrainees} onChange={handleTraineeSelection}>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Select</Th>
                    <Th>Name</Th>
                    <Th>Profile Picture</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {filteredTrainees.map((trainee) => (
                    <Tr key={trainee.userId}>
                      <Td>
                        <Checkbox value={trainee.userId} />
                      </Td>
                      <Td>
                        {trainee.firstName} {trainee.lastName}
                      </Td>
                      <Td>
                        <Avatar
                          size="sm"
                          src={trainee.profilePictureURL}
                          name={`${trainee.firstName} ${trainee.lastName}`}
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </CheckboxGroup>
            <Button colorScheme="blue" mt={6} onClick={handleAssignTrainees}>
              Assign Trainees
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default StaffBreakdown;