import React, { useState, useEffect } from "react";
import {
  Box,
  useToast,
  Button,
  Input,
  FormControl,
  FormLabel,
  Heading,
  Flex,
  Textarea,
  IconButton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import { useParams, useNavigate } from "react-router-dom";
import { db } from "../../../Firebase";
import { doc, getDoc, updateDoc, deleteDoc } from "firebase/firestore";
import { useAuth } from "../../../contexts/AuthContext";
import IconSelector from "./modules/IconSelector";
import TagHandler from "./modules/TagHandler";
import FormBuilder from "./modules/FormBuilder";
import { FaTrash } from "react-icons/fa";

const EvaluationsEdit = () => {
  const { userDetails } = useAuth();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedIcon, setSelectedIcon] = useState("");
  const [tags, setTags] = useState([]);
  const [fields, setFields] = useState([]);
  const [totalFormPoints, setTotalFormPoints] = useState(0);
  const [totalPassFailCount, setTotalPassFailCount] = useState(0);
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
  const cancelRef = React.useRef();
  const { evaluationId } = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    const fetchEvaluation = async () => {
      try {
        const organizationId = userDetails.organizationId;
        const evaluationRef = doc(
          db,
          "org",
          organizationId,
          "evaluations",
          evaluationId
        );
        const evaluationDoc = await getDoc(evaluationRef);
        if (evaluationDoc.exists()) {
          const evaluationData = evaluationDoc.data();
          setName(evaluationData.name);
          setDescription(evaluationData.description);
          setSelectedIcon(evaluationData.icon);
          setTags(evaluationData.tags || []);
          setFields(evaluationData.fields || []);
          setTotalFormPoints(evaluationData.totalFormPoints || 0);
          setTotalPassFailCount(evaluationData.totalPassFailCount || 0);
        } else {
          console.log("Evaluation not found");
        }
      } catch (error) {
        console.error("Error fetching evaluation: ", error);
      }
    };

    fetchEvaluation();
  }, [evaluationId, userDetails.organizationId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const organizationId = userDetails.organizationId;
      const evaluationRef = doc(
        db,
        "org",
        organizationId,
        "evaluations",
        evaluationId
      );

      // Prepare the fields data
      const updatedFields = fields.map(field => {
        if (field.type === 'multi-choice' && field.grading !== 'points') {
          const { totalPoints, ...fieldWithoutTotalPoints } = field;
          return fieldWithoutTotalPoints;
        }
        return field;
      });

      // First, clear the existing fields
      await updateDoc(evaluationRef, {
        fields: [],
      });

      // Then, update the document with the new fields and other data
      await updateDoc(evaluationRef, {
        name,
        description,
        icon: selectedIcon,
        tags,
        fields: updatedFields,
        totalFormPoints,
        totalPassFailCount,
      });

      toast({
        title: "Evaluation updated successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      navigate("/dashboard/evaluations");
    } catch (error) {
      console.error("Error updating evaluation: ", error);
      toast({
        title: "Error updating evaluation",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDelete = async () => {
    try {
      const organizationId = userDetails.organizationId;
      const evaluationRef = doc(
        db,
        "org",
        organizationId,
        "evaluations",
        evaluationId
      );
      await deleteDoc(evaluationRef);
      toast({
        title: "Evaluation deleted successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      navigate("/dashboard/evaluations");
    } catch (error) {
      console.error("Error deleting evaluation: ", error);
      toast({
        title: "Error deleting evaluation",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box maxWidth="700px" mx="auto">
      <Heading mb={6}>Edit Evaluation</Heading>
      <Box as="form" onSubmit={handleSubmit}>
        <Flex alignItems="center" mb={4}>
          <Box mr={4}>
            <IconSelector
              selectedIcon={selectedIcon}
              setSelectedIcon={setSelectedIcon}
            />
          </Box>
          <FormControl id="name">
            <FormLabel fontWeight="bold" fontSize="20px">Evaluation Name</FormLabel>
            <Input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter evaluation name..."
              required
              bg="gray.100"
              borderWidth="0px"
            />
          </FormControl>
        </Flex>
        <FormControl id="description" mb={4}>
          <FormLabel fontWeight="bold" fontSize="20px">Description</FormLabel>
          <Textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter evaluation description..."
            resize="none"
            bg="gray.100"
            borderWidth="0px"
          />
        </FormControl>
        <FormControl id="tags" mb={4}>
          <FormLabel fontWeight="bold" fontSize="20px">Tags</FormLabel>
          <TagHandler tags={tags} setTags={setTags} />
        </FormControl>
        <FormControl id="form-builder" mb={4}>
          <FormLabel fontWeight="bold" fontSize="20px">Form Builder</FormLabel>
          <FormBuilder 
            fields={fields} 
            setFields={setFields}
            totalFormPoints={totalFormPoints}
            setTotalFormPoints={setTotalFormPoints}
            totalPassFailCount={totalPassFailCount}
            setTotalPassFailCount={setTotalPassFailCount}
          />
        </FormControl>
        <Flex justifyContent="space-between" alignItems="center">
          <Button type="submit" colorScheme="blue" width="calc(100% - 60px)">
            Save
          </Button>
          <IconButton
            icon={<FaTrash />}
            colorScheme="red"
            onClick={() => setIsDeleteAlertOpen(true)}
          />
        </Flex>
      </Box>

      <AlertDialog
        isOpen={isDeleteAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsDeleteAlertOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Evaluation
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete this evaluation? This action cannot be undone.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsDeleteAlertOpen(false)}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDelete} ml={3}>
                Yes
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default EvaluationsEdit;
