import React, { useState, useEffect } from 'react';
import { doc, getDoc, onSnapshot, updateDoc } from 'firebase/firestore';
import { Box, Table, Thead, Tbody, Tr, Th, Td, Heading, Image, Avatar, Button } from '@chakra-ui/react';
import { db, auth } from '../../../Firebase';

function IncomingRequests() {
  const [pendingMembers, setPendingMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const fetchPendingMembers = async () => {
          try {
            // Get the current user's document
            const userDocRef = doc(db, 'users', user.uid);
            const userDocSnapshot = await getDoc(userDocRef);
            if (userDocSnapshot.exists()) {
              const userData = userDocSnapshot.data();
              const organizationId = userData.organizationId;
              if (organizationId) {
                // Get the organization document and listen for real-time updates
                const organizationDocRef = doc(db, 'org', organizationId);
                const unsubscribeOrg = onSnapshot(organizationDocRef, async (organizationDocSnapshot) => {
                  if (organizationDocSnapshot.exists()) {
                    const organizationData = organizationDocSnapshot.data();
                    const staff = organizationData.staff || [];

                    // Find the current user's role and department
                    const currentUserStaffMember = staff.find(member => member.userId === user.uid);

                    // Filter pending staff members based on department and admin status
                    const pendingStaff = staff.filter(member =>
                      !member.verified &&
                      member.department === currentUserStaffMember.department &&
                      (currentUserStaffMember.role === 'admin' || member.role !== 'admin')
                    );

                    // Fetch user details for each pending staff member from the users collection
                    const pendingMembersWithDetails = await Promise.all(pendingStaff.map(async (member) => {
                      const staffDocRef = doc(db, 'users', member.userId);
                      const staffDocSnapshot = await getDoc(staffDocRef);
                      if (staffDocSnapshot.exists()) {
                        const staffData = staffDocSnapshot.data();
                        return {
                          ...member,
                          email: staffData.email,
                          firstName: staffData.firstName,
                          lastName: staffData.lastName,
                          profilePictureURL: staffData.profilePictureURL
                        };
                      } else {
                        return member;
                      }
                    }));

                    setPendingMembers(pendingMembersWithDetails);
                  } else {
                    console.error('Organization document not found');
                  }
                });

                return unsubscribeOrg;
              } else {
                console.error('User document does not contain organizationId');
              }
            } else {
              console.error('User document not found');
            }
          } catch (error) {
            console.error('Error fetching pending staff members:', error);
          } finally {
            setIsLoading(false);
          }
        };

        const unsubscribeOrg = await fetchPendingMembers();

        return () => {
          if (unsubscribeOrg) {
            unsubscribeOrg();
          }
        };
      } else {
        setIsLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleAccept = async (memberId) => {
    try {
      const user = auth.currentUser;
      const userDocRef = doc(db, "users", user.uid);
      const userDocSnapshot = await getDoc(userDocRef);
      const userData = userDocSnapshot.data();
      const organizationId = userData.organizationId;
      const organizationDocRef = doc(db, "org", organizationId);

      // Get the current staff array from the organization document
      const organizationDocSnapshot = await getDoc(organizationDocRef);
      const organizationData = organizationDocSnapshot.data();
      const staff = organizationData.staff || [];

      // Update the verified status of the selected staff member
      const updatedStaff = staff.map(member => {
        if (member.userId === memberId) {
          return {
            ...member,
            verified: true,
          };
        }
        return member;
      });

      // Update the organization document with the updated staff array
      await updateDoc(organizationDocRef, {
        staff: updatedStaff,
      });

      // Update the pending members state by removing the accepted member
      setPendingMembers(pendingMembers.filter(member => member.userId !== memberId));
    } catch (error) {
      console.error("Error updating staff member status:", error);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Box>
      <Heading as="h3" size="lg" mb={4}>
        Incoming Requests
      </Heading>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Profile Picture</Th>
            <Th>First Name</Th>
            <Th>Last Name</Th>
            <Th>Email</Th>
            <Th>Role</Th>
            <Th>Department</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {pendingMembers.map(member => (
            <Tr key={member.userId}>
              <Td>
                {member.profilePictureURL ? (
                  <Box width="48px" height="48px" borderRadius="50%" overflow="hidden">
                    <Image src={member.profilePictureURL} alt="Profile" objectFit="cover" width="100%" height="100%" />
                  </Box>
                ) : (
                  <Avatar name={`${member.firstName} ${member.lastName}`} size="md" />
                )}
              </Td>
              <Td>{member.firstName}</Td>
              <Td>{member.lastName}</Td>
              <Td>{member.email}</Td>
              <Td>{member.role}</Td>
              <Td>{member.department}</Td>
              <Td>
                <Button colorScheme="teal" onClick={() => handleAccept(member.userId)}>
                  Accept
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
}

export default IncomingRequests;