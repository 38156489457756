import React, { useEffect, useState } from 'react';
import { Box, Heading, Text, VStack, HStack, Badge, Divider } from '@chakra-ui/react';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { db } from "../../../Firebase";
import { useAuth } from "../../../contexts/AuthContext";

const Overview = () => {
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const { userDetails } = useAuth();

  useEffect(() => {
    const fetchReports = async () => {
      if (userDetails?.organizationId) {
        try {
          const reportsRef = collection(db, 'org', userDetails.organizationId, 'reports');
          const q = query(reportsRef, orderBy('timeSubmitted', 'desc'));
          const querySnapshot = await getDocs(q);
          
          const fetchedReports = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));

          // Filter reports to only include those submitted by or for the current user
          const filteredReports = fetchedReports.filter(report => 
            report.submittedBy.id === userDetails.uid || report.submittedFor.id === userDetails.uid
          );

          setReports(filteredReports);
        } catch (error) {
          console.error("Error fetching reports:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchReports();
  }, [userDetails]);

  const formatDate = (date) => {
    if (date instanceof Date) {
      return date.toLocaleString();
    } else if (typeof date === 'string') {
      return new Date(date).toLocaleString();
    } else if (date && date.seconds) {
      return new Date(date.seconds * 1000).toLocaleString();
    }
    return 'Invalid Date';
  };

  return (
    <Box>
      <Heading fontSize="4xl" fontWeight="bold" mb={4}>
        Hey {userDetails?.firstName} {userDetails?.lastName},
      </Heading>
      {loading ? (
        <Text>Loading your reports...</Text>
      ) : reports.length === 0 ? (
        <Text>No reports found. Contact your admin to get started.</Text>
      ) : (
        <>
          <Text>You have {reports.length} reports:</Text>
          <VStack spacing={4} align="stretch" mt={4}>
            {reports.map((report) => (
              <Box key={report.id} p={4} borderWidth={1} borderRadius="md">
                <Heading size="md">{report.evaluationName}</Heading>
                <HStack>
                  <Text>Submitted At: {formatDate(report.timeSubmitted)}</Text>
                  <Badge colorScheme={report.status === 'verified' ? 'green' : 'red'}>
                    Status: {report.status || 'N/A'}
                  </Badge>
                </HStack>
                <Text>Submitted By: {report.submittedBy.name}</Text>
                <Text>Submitted For: {report.submittedFor.name}</Text>
                <Divider my={2} />
              </Box>
            ))}
          </VStack>
        </>
      )}
    </Box>
  );
};

export default Overview;
