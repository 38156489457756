import React, { useState } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  List,
  ListItem,
  Flex,
  Avatar,
  Text,
  Box,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";

const StaffMemberSelector = ({ staffMembers, selectedStaffMember, onStaffMemberChange }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const filteredStaffMembers = staffMembers.filter((staff) =>
    `${staff.firstName} ${staff.lastName}`.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <FormControl mb={4}>
      <FormLabel htmlFor="staffMember">Select Staff Member</FormLabel>
      <Popover isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <PopoverTrigger>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <Icon as={SearchIcon} color="gray.300" />
            </InputLeftElement>
            <Input
              id="staffMember"
              value={selectedStaffMember ? `${selectedStaffMember.firstName} ${selectedStaffMember.lastName}` : ""}
              placeholder="Search staff member"
              readOnly
              onClick={() => setIsOpen(true)}
              borderWidth="0px"
              bg="gray.100"
            />
          </InputGroup>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader>Select Staff Member</PopoverHeader>
          <PopoverBody>
            <InputGroup mb={4}>
              <InputLeftElement pointerEvents="none">
                <Icon as={SearchIcon} color="gray.300" />
              </InputLeftElement>
              <Input
                placeholder="Search by name"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </InputGroup>
            <List spacing={2}>
              {filteredStaffMembers.map((staff) => (
                <ListItem
                  key={staff.userId}
                  onClick={() => {
                    onStaffMemberChange(staff);
                    setIsOpen(false);
                  }}
                  cursor="pointer"
                >
                  <Flex align="center">
                    <Avatar size="sm" name={`${staff.firstName} ${staff.lastName}`} src={staff.profilePicture} mr={2} />
                    <Box>
                      <Text>{`${staff.firstName} ${staff.lastName}`}</Text>
                    </Box>
                  </Flex>
                </ListItem>
              ))}
            </List>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </FormControl>
  );
};

export default StaffMemberSelector;