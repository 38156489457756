import React, { createContext, useContext, useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../Firebase';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [userRole, setUserRole] = useState("");
  const [userDetails, setUserDetails] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserDetails(userData);

          const organizationId = userData.organizationId;
          if (organizationId) {
            const organizationRef = doc(db, 'org', organizationId);
            const organizationDoc = await getDoc(organizationRef);

            if (organizationDoc.exists()) {
              const organizationData = organizationDoc.data();
              const staffMembers = organizationData.staff || [];
              const currentUserStaff = staffMembers.find(
                (member) => member.userId === user.uid
              );

              if (currentUserStaff) {
                setUserRole(currentUserStaff.role.toLowerCase());
              }
            }
          }
        }
        setCurrentUser(user);
      } else {
        setCurrentUser(null);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    userRole,
    userDetails,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
