import React, { useState, useEffect } from 'react';
import { Box, Button, FormControl, FormLabel, Input, Heading, Alert, AlertIcon, AlertTitle, AlertDescription, Spinner, VStack, Image, Text, Link, HStack } from '@chakra-ui/react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { auth, db } from '../Firebase';
import logo from '../assets/whitelogo.svg';
import { useAuth } from '../contexts/AuthContext';

function Signup() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser) {
      navigate('/onboarding');
    }
  }, [currentUser, navigate]);

  const handleSignup = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Create a new user document in Firestore
      await setDoc(doc(db, 'users', user.uid), {
        uid: user.uid,
        firstName,
        lastName,
        phone,
        email,
      });

      navigate('/onboarding');
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  const handlePhoneChange = (e) => {
    const formattedPhone = e.target.value.replace(/\D/g, '').slice(0, 10);
    setPhone(formattedPhone);
  };

  if (loading) {
    return (
      <Box bg="gray.100" minH="100vh" display="flex" alignItems="center" justifyContent="center">
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <Box bg="gray.100" minH="100vh" display="flex" alignItems="center" justifyContent="center">
      <Box w="500px" p={8} borderWidth={1} borderRadius={8} boxShadow="lg" bg="white">
        <Box mb={6} textAlign="center">
          <Box display="flex" justifyContent="center" mb={10}>
            <Image src={logo} alt="White Logo" w="300px" />
          </Box>
          <Heading>Signup</Heading>
        </Box>
        {error && (
          <Alert status="error" mb={4} borderRadius={4}>
            <AlertIcon />
            <Box>
              <AlertTitle>Error</AlertTitle>
              <AlertDescription>{error}</AlertDescription>
            </Box>
          </Alert>
        )}
        <form onSubmit={handleSignup}>
          <VStack spacing={4}>
            <HStack spacing={4}>
              <FormControl>
                <FormLabel>First Name</FormLabel>
                <Input
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder="Enter your first name"
                />
              </FormControl>
              <FormControl>
                <FormLabel>Last Name</FormLabel>
                <Input
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="Enter your last name"
                />
              </FormControl>
            </HStack>
            <FormControl>
              <FormLabel>Phone</FormLabel>
              <Input
                type="tel"
                value={phone}
                onChange={handlePhoneChange}
                placeholder="Enter your phone number"
                maxLength={14}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
              />
            </FormControl>
            <FormControl>
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your password"
              />
            </FormControl>
            <Button type="submit" colorScheme="blue" size="lg" width="full">
              Signup
            </Button>
          </VStack>
        </form>
        <Text mt={4} textAlign="center">
          Already have an account?{' '}
          <Link as={RouterLink} to="/" color="blue.500">
            Login
          </Link>
        </Text>
      </Box>
    </Box>
  );
}

export default Signup;
