import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Text,
  VStack,
  Input,
  Button,
  useToast,
  FormControl,
  FormLabel,
  Spinner,
  Avatar,
  IconButton,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Select,
} from "@chakra-ui/react";
import { BsUpload } from "react-icons/bs";
import { db, storage } from "../../../Firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL, getStorage, getMetadata } from "firebase/storage";
import { useAuth } from "../../../contexts/AuthContext";

const Profile = () => {
  const { currentUser, userDetails, userRole } = useAuth();
  const [editedData, setEditedData] = useState(userDetails);
  const [isEdited, setIsEdited] = useState(false);
  const [profilePicture, setProfilePicture] = useState(null);
  const [profilePicturePreview, setProfilePicturePreview] = useState(null);
  const [profilePictureURL, setProfilePictureURL] = useState(userDetails.profilePictureURL || "");
  const [selectedDepartment, setSelectedDepartment] = useState(userDetails.department || "");
  const [selectedRole, setSelectedRole] = useState(userRole || "");
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [confirmationType, setConfirmationType] = useState("");
  const [departments, setDepartments] = useState([]);
  const toast = useToast();

  useEffect(() => {
    if (userDetails.organizationId) {
      const fetchOrganizationData = async () => {
        const organizationDocRef = doc(db, "org", userDetails.organizationId);
        const organizationDocSnapshot = await getDoc(organizationDocRef);

        if (organizationDocSnapshot.exists()) {
          const organizationData = organizationDocSnapshot.data();
          setDepartments(organizationData.departments || []);
        }
      };

      fetchOrganizationData();
    }
  }, [userDetails.organizationId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setIsEdited(true);
  };

  const handleProfilePictureChange = (e) => {
    const file = e.target.files[0];
    setProfilePicture(file);
    setProfilePicturePreview(URL.createObjectURL(file));
    setIsEdited(true);
  };

  const handleUpdateProfile = async () => {
    try {
      if (currentUser) {
        const userDocRef = doc(db, "users", currentUser.uid);
        const organizationId = editedData.organizationId || userDetails.organizationId;

        if (organizationId) {
          const folderPath = `${organizationId}/profilepicture`;
          const storageRef = getStorage();
          const folderRef = ref(storageRef, folderPath);
          const profilePictureRef = ref(storageRef, `${folderPath}/${currentUser.uid}`);

          await createFolderIfNotExists(folderRef);

          if (profilePicture) {
            await uploadBytes(profilePictureRef, profilePicture);
            const downloadURL = await getDownloadURL(profilePictureRef);
            await updateDoc(userDocRef, { ...editedData, profilePictureURL: downloadURL });
            setProfilePictureURL(downloadURL);
          } else {
            await updateDoc(userDocRef, editedData);
          }

          setIsEdited(false);
          setProfilePicture(null);
          setProfilePicturePreview(null);
          toast({
            title: "Profile updated",
            description: "Your profile has been successfully updated.",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Missing Organization ID",
            description: "Please provide an organization ID to upload a profile picture.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      toast({
        title: "Error updating profile",
        description: "An error occurred while updating your profile.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const createFolderIfNotExists = async (folderRef) => {
    try {
      const folderPath = folderRef.fullPath;
      const folderPathSegments = folderPath.split("/");

      for (let i = 1; i < folderPathSegments.length; i++) {
        const currentPath = folderPathSegments.slice(0, i + 1).join("/");
        const currentRef = ref(storage, currentPath);
        try {
          await getMetadata(currentRef);
        } catch (error) {
          if (error.code === "storage/object-not-found") {
            const emptyFile = new Uint8Array();
            await uploadBytes(currentRef, emptyFile);
          } else {
            throw error;
          }
        }
      }
    } catch (error) {
      console.error("Error creating folder:", error);
    }
  };

  const handleDepartmentChange = (e) => {
    setSelectedDepartment(e.target.value);
    setConfirmationType("department");
    setIsConfirmationModalOpen(true);
  };

  const handleRoleChange = (e) => {
    setSelectedRole(e.target.value);
    setConfirmationType("role");
    setIsConfirmationModalOpen(true);
  };

  const handleConfirmChange = async () => {
    try {
      if (currentUser) {
        const userDocRef = doc(db, "users", currentUser.uid);
        const organizationId = editedData.organizationId || userDetails.organizationId;

        if (organizationId) {
          const updatedData = {
            ...editedData,
            verified: false,
          };

          if (confirmationType === "department") {
            updatedData.department = selectedDepartment;
          } else if (confirmationType === "role") {
            updatedData.role = selectedRole;
          }

          await updateDoc(userDocRef, updatedData);

          const organizationDocRef = doc(db, "org", organizationId);
          const organizationDocSnapshot = await getDoc(organizationDocRef);

          if (organizationDocSnapshot.exists()) {
            const organizationData = organizationDocSnapshot.data();
            const staffMembers = organizationData.staff || [];

            const updatedStaffMembers = staffMembers.map((member) => {
              if (member.userId === currentUser.uid) {
                return {
                  ...member,
                  department: updatedData.department,
                  role: updatedData.role,
                  verified: false,
                };
              }
              return member;
            });

            await updateDoc(organizationDocRef, {
              staff: updatedStaffMembers,
            });
          }

          setEditedData(updatedData);
          toast({
            title: `${confirmationType === "department" ? "Department" : "Role"} updated`,
            description: `Your ${confirmationType === "department" ? "department" : "role"} has been successfully updated.`,
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setIsConfirmationModalOpen(false);
        }
      }
    } catch (error) {
      console.error(`Error updating ${confirmationType}:`, error);
      toast({
        title: `Error updating ${confirmationType}`,
        description: `An error occurred while updating your ${confirmationType}.`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box maxW={500}>
      <Heading fontSize="4xl" fontWeight="bold" mb={4}>
        Profile
      </Heading>
      {currentUser ? (
        <>
          <VStack align="start" spacing={4}>
            <FormControl>
              <FormLabel htmlFor="profilePicture">Profile Picture</FormLabel>
              <Box display="flex" alignItems="center" gap={4}>
                <IconButton
                  aria-label="Upload Profile Picture"
                  icon={<BsUpload />}
                  onClick={() => document.getElementById("profilePicture").click()}
                />
                {profilePicturePreview ? (
                  <Image
                    src={profilePicturePreview}
                    alt="Profile Picture Preview"
                    boxSize="100px"
                    objectFit="cover"
                    borderRadius="100"
                  />
                ) : (
                  <Avatar
                    src={profilePictureURL}
                    size="xl"
                    name={`${editedData.firstName} ${editedData.lastName}`}
                  />
                )}
              </Box>
              <Input
                id="profilePicture"
                name="profilePicture"
                type="file"
                accept="image/*"
                onChange={handleProfilePictureChange}
                style={{ display: "none" }}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="firstName">First Name</FormLabel>
              <Input
                id="firstName"
                name="firstName"
                value={editedData.firstName || ""}
                onChange={handleInputChange}
                placeholder="First Name"
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="lastName">Last Name</FormLabel>
              <Input
                id="lastName"
                name="lastName"
                value={editedData.lastName || ""}
                onChange={handleInputChange}
                placeholder="Last Name"
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="email">Email</FormLabel>
              <Input
                id="email"
                name="email"
                value={editedData.email || ""}
                onChange={handleInputChange}
                placeholder="Email"
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="phone">Phone</FormLabel>
              <Input
                id="phone"
                name="phone"
                value={editedData.phone || ""}
                onChange={handleInputChange}
                placeholder="Phone"
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="organizationId">Organization ID</FormLabel>
              <Input
                id="organizationId"
                name="organizationId"
                value={editedData.organizationId || ""}
                onChange={handleInputChange}
                placeholder="Organization ID"
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="role">Role</FormLabel>
              <Select
                id="role"
                name="role"
                value={selectedRole}
                onChange={handleRoleChange}
                placeholder="Select Role"
              >
                <option value="admin">Admin</option>
                <option value="trainer">Trainer</option>
                <option value="trainee">Trainee</option>
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="department">Department</FormLabel>
              <Select
                id="department"
                name="department"
                value={selectedDepartment}
                onChange={handleDepartmentChange}
                placeholder="Select Department"
              >
                {departments.map((department) => (
                  <option key={department} value={department}>
                    {department}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="verified">Verified</FormLabel>
              <Box
                bgColor={userDetails.verified ? "green.100" : "red.100"}
                border="2px solid"
                borderColor={userDetails.verified ? "green.500" : "red.500"}
                borderRadius="md"
                p={2}
                fontSize="sm"
                fontWeight="bold"
              >
                {userDetails.verified ? (
                  <Text color="green.700" textTransform="uppercase">
                    Verified
                  </Text>
                ) : (
                  <Text color="red.700" textTransform="uppercase">
                    Unverified
                  </Text>
                )}
              </Box>
            </FormControl>
          </VStack>
          <Button
            mt={4}
            colorScheme="blue"
            onClick={handleUpdateProfile}
            isDisabled={!isEdited}
          >
            Update Profile
          </Button>
        </>
      ) : (
        <Spinner size="xl" />
      )}

      <Modal
        isOpen={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm {confirmationType === "department" ? "Department" : "Role"} Change</ModalHeader>
          <ModalBody>
            <Text>
              Are you sure you want to switch from {confirmationType === "department" ? userDetails.department : userRole} to{" "}
              {confirmationType === "department" ? selectedDepartment : selectedRole}?
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleConfirmChange}>
              Yes
            </Button>
            <Button onClick={() => setIsConfirmationModalOpen(false)}>No</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Profile;
