import React, { useState, useEffect, useCallback } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  Box,
  Heading,
  Text,
  Input,
  Button,
  VStack,
  HStack,
  FormControl,
  FormLabel,
  useToast,
  IconButton,
  List,
  ListItem,
} from '@chakra-ui/react';
import { DeleteIcon, DragHandleIcon } from '@chakra-ui/icons';
import { db } from '../../../Firebase';
import { useAuth } from '../../../contexts/AuthContext';

const DraggableDepartment = ({ id, text, index, moveDepartment, onDelete }) => {
  const [, drag] = useDrag({
    type: 'DEPARTMENT',
    item: { id, index },
  });

  const [, drop] = useDrop({
    accept: 'DEPARTMENT',
    hover: (item, monitor) => {
      if (!monitor.isOver({ shallow: true })) return;
      if (item.index === index) return;
      moveDepartment(item.index, index);
      item.index = index;
    },
  });

  return (
    <ListItem ref={(node) => drag(drop(node))} mb={2}>
      <HStack spacing={2} p={2} bg="gray.100" borderRadius="md">
        <IconButton
          icon={<DragHandleIcon />}
          variant="ghost"
          cursor="grab"
          aria-label="Drag to reorder"
        />
        <Text flex="1">{text}</Text>
        <IconButton
          icon={<DeleteIcon />}
          onClick={() => onDelete(index)}
          size="sm"
          aria-label="Delete"
        />
      </HStack>
    </ListItem>
  );
};

const Organization = () => {
  const { userDetails } = useAuth();
  const [organizationName, setOrganizationName] = useState('');
  const [organizationId, setOrganizationId] = useState('');
  const [departments, setDepartments] = useState([]);
  const [newDepartment, setNewDepartment] = useState('');
  const [isChanged, setIsChanged] = useState(false);
  const toast = useToast();

  useEffect(() => {
    const fetchOrganizationData = async () => {
      try {
        const orgDocRef = doc(db, 'org', userDetails.organizationId);
        const orgDocSnapshot = await getDoc(orgDocRef);
        if (orgDocSnapshot.exists()) {
          const orgData = orgDocSnapshot.data();
          setOrganizationName(orgData.name);
          setOrganizationId(orgDocSnapshot.id);
          setDepartments(orgData.departments || []);
        } else {
          console.error('Organization document not found');
        }
      } catch (error) {
        console.error('Error fetching organization information:', error);
      }
    };

    if (userDetails.organizationId) {
      fetchOrganizationData();
    }
  }, [userDetails.organizationId]);

  const handleNameChange = (e) => {
    setOrganizationName(e.target.value);
    setIsChanged(true);
  };

  const handleAddDepartment = () => {
    if (newDepartment.trim()) {
      setDepartments([...departments, newDepartment.trim()]);
      setNewDepartment('');
      setIsChanged(true);
    }
  };

  const handleDeleteDepartment = (index) => {
    setDepartments(departments.filter((_, i) => i !== index));
    setIsChanged(true);
  };

  const moveDepartment = useCallback((dragIndex, hoverIndex) => {
    setDepartments((prevDepartments) => {
      const updatedDepartments = [...prevDepartments];
      const [reorderedItem] = updatedDepartments.splice(dragIndex, 1);
      updatedDepartments.splice(hoverIndex, 0, reorderedItem);
      return updatedDepartments;
    });
    setIsChanged(true);
  }, []);

  const handleUpdateClick = async () => {
    try {
      const orgDocRef = doc(db, 'org', userDetails.organizationId);
      await updateDoc(orgDocRef, {
        name: organizationName,
        departments: departments,
      });
      setIsChanged(false);
      toast({
        title: 'Organization updated',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating organization:', error);
      toast({
        title: 'Error updating organization',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(organizationId);
    toast({
      title: 'Organization code copied!',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Box maxW="500px">
      <Heading as="h2" size="xl" mb={6}>
        Organization
      </Heading>
      <VStack spacing={4} align="stretch">
        <FormControl>
          <FormLabel htmlFor="organization-name">Organization Name</FormLabel>
          <Input
            id="organization-name"
            value={organizationName}
            onChange={handleNameChange}
            placeholder="Enter organization name"
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="new-department">Add Department</FormLabel>
          <HStack>
            <Input
              id="new-department"
              value={newDepartment}
              onChange={(e) => setNewDepartment(e.target.value)}
              placeholder="Enter department name"
            />
            <Button colorScheme="blue" onClick={handleAddDepartment}>
              Add
            </Button>
          </HStack>
        </FormControl>
        <Box>
          <Text fontWeight="bold" mb={2}>
            Departments
          </Text>
          <DndProvider backend={HTML5Backend}>
            <List spacing={2}>
              {departments.map((department, index) => (
                <DraggableDepartment
                  key={index}
                  id={index}
                  text={department}
                  index={index}
                  moveDepartment={moveDepartment}
                  onDelete={handleDeleteDepartment}
                />
              ))}
            </List>
          </DndProvider>
        </Box>
        <Button
          colorScheme="blue"
          onClick={handleUpdateClick}
          isDisabled={!isChanged}
          width="full"
        >
          Update
        </Button>
        <HStack justifyContent="space-between">
          <Text>Organization Code: {organizationId}</Text>
          <Button colorScheme="blue" onClick={copyToClipboard}>
            Copy
          </Button>
        </HStack>
      </VStack>
    </Box>
  );
};

export default Organization;