import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Select,
} from '@chakra-ui/react';

const SelectionPicker = ({ isOpen, onClose, onSave }) => {
  const [fieldType, setFieldType] = useState('single-line');

  const handleSave = () => {
    onSave({ type: fieldType });
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add New Field</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Select
            placeholder="Select field type"
            value={fieldType}
            onChange={(e) => setFieldType(e.target.value)}
          >
            <option value="single-line">Single Line Input</option>
            <option value="multi-line">Multi Line Input</option>
            <option value="multi-choice">Multi Option Select</option>
          </Select>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={handleSave} mr={3}>
            Add
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SelectionPicker;