import React from "react";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import Signup from "./components/Signup";
import Login from "./components/Login";
import Main from "./components/Main";
import Onboarding from "./components/Onboarding";
import Overview from "./components/dashboard/overview/Overview";
import Organization from "./components/dashboard/organization/Organization";
import Evaluations from "./components/dashboard/evaluations/Evaluations";
import EvaluationsEdit from "./components/dashboard/evaluations/EvaluationsEdit";
import EvaluationsForm from "./components/dashboard/evaluations/EvaluationsForm";
import Protocols from "./components/dashboard/protocols/Protocols";
import ProtocolsDocuments from "./components/dashboard/protocols/Protocols";
import Profile from "./components/dashboard/profile/Profile";
import StaffMain from "./components/dashboard/staff/StaffMain";
import Reports from "./components/dashboard/reports/Reports";
// import MyTrainee from "./components/dashboard/my-trainee/MyTrainee";
import MyTrainer from "./components/dashboard/my-trainer/MyTrainer";
import MyTrainee from "./components/dashboard/my-trainee/MyTrainee";
import ReportDetails from "./components/dashboard/reports/ReportDetails";
import Contact from "./components/dashboard/contact/Contact";
import StaffDetail from "./components/dashboard/staff/StaffDetail";

// Custom theme with Reddit Sans font
const theme = extendTheme({
  fonts: {
    heading: "Reddit Sans, sans-serif",
    body: "Reddit Sans, sans-serif",
  },
});

function App() {
  return (
    <ChakraProvider theme={theme}>
      <AuthProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/onboarding" element={<Onboarding />} />
            <Route path="/dashboard" element={<Main />}>
              <Route index element={<Navigate to="overview" />} />

              <Route path="overview" element={<Overview />} />

              <Route path="organization" element={<Organization />} />

              <Route path="evaluations" element={<Evaluations />} />
              <Route path="evaluations/:evaluationId" element={<EvaluationsForm />} />
              <Route path="evaluations/:evaluationId/edit" element={<EvaluationsEdit />} />

              <Route path="staff" element={<StaffMain />} />
              <Route path="staff/:userId" element={<StaffDetail />} />
              
              <Route path="my-trainee" element={<MyTrainee />} />
              
              <Route path="my-trainer" element={<MyTrainer />} />

              <Route path="reports" element={<Reports />} />
              <Route path="reports/:reportId" element={<ReportDetails />} />

              <Route path="protocols" element={<Protocols />} />
              <Route path="protocols/documents" element={<ProtocolsDocuments />} />

              <Route path="profile" element={<Profile />} />

              <Route path="contact" element={<Contact />} />

            </Route>
          </Routes>
        </Router>
      </AuthProvider>
    </ChakraProvider>
  );
}

export default App;
