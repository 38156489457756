import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import {
  Box,
  VStack,
  Image,
  Button,
  Text,
  Divider,
  Flex,
  Fade,
} from "@chakra-ui/react";
import logo from "../assets/bluelogo.svg";
import icon from "../assets/trans.png";
import {
  FiGrid,
  FiBookOpen,
  FiUser,
  FiLogOut,
  FiMessageSquare,
  FiChevronLeft,
  FiChevronRight,
  FiUsers,
  FiShield,
  FiFileText,
  FiClipboard,
} from "react-icons/fi";
import { useAuth } from '../contexts/AuthContext';
import { auth } from "../Firebase";
import Breadcrumbs from './dashboard/utils/Breadcrumbs'; // Import Breadcrumbs component

const sidebarButtons = {
  director: [
    { label: "Overview", icon: FiGrid, route: "overview" },
    { label: "Organization", icon: FiShield, route: "organization" },
    { label: "Staff", icon: FiUsers, route: "staff" },
    { label: "Evaluations", icon: FiClipboard, route: "evaluations" },
    { label: "Reports", icon: FiFileText, route: "reports" },
    { label: "Protocols", icon: FiBookOpen, route: "protocols" },
    { label: "My Profile", icon: FiUser, route: "profile" },
  ],
  admin: [
    { label: "Overview", icon: FiGrid, route: "overview" },
    { label: "Staff", icon: FiUsers, route: "staff" },
    { label: "Evaluations", icon: FiClipboard, route: "evaluations" },
    { label: "Reports", icon: FiFileText, route: "reports" },
    { label: "Protocols", icon: FiBookOpen, route: "protocols" },
    { label: "My Profile", icon: FiUser, route: "profile" },
  ],
  trainer: [
    { label: "Overview", icon: FiGrid, route: "overview" },
    { label: "Evaluations", icon: FiClipboard, route: "evaluations" },
    { label: "My Trainee", icon: FiUser, route: "my-trainee" },
    { label: "Reports", icon: FiFileText, route: "reports" },
    { label: "Protocols", icon: FiBookOpen, route: "protocols" },
    { label: "My Profile", icon: FiUser, route: "profile" },
  ],
  trainee: [
    { label: "Overview", icon: FiGrid, route: "overview" },
    { label: "My Trainer", icon: FiUser, route: "my-trainer" },
    { label: "Reports", icon: FiFileText, route: "reports" },
    { label: "Protocols", icon: FiBookOpen, route: "protocols" },
    { label: "My Profile", icon: FiUser, route: "profile" },
  ],
};

const Main = () => {
  const { currentUser, userRole, userDetails } = useAuth();
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [isSidebarTransitioning, setIsSidebarTransitioning] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [showText, setShowText] = useState(true);

  useEffect(() => {
    if (!currentUser) {
      navigate("/");
    }
  }, [currentUser, navigate]);

  const isRouteActive = (route) => location.pathname === `/dashboard/${route}`;

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const toggleSidebar = () => {
    setIsSidebarTransitioning(true);
    setShowText(false);
    setTimeout(() => {
      setIsSidebarCollapsed(!isSidebarCollapsed);
      setIsSidebarTransitioning(false);
      if (!isSidebarCollapsed) {
        setTimeout(() => setShowText(true), 1400);
      }
    }, 300);
  };

  return (
    <Flex minHeight="100vh">
      <Box
        as="aside"
        width={isSidebarCollapsed ? "100px" : "280px"}
        bg="#001c36"
        color="white"
        p={6}
        position="fixed"
        top={0}
        left={0}
        bottom={0}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        transition="width 0.3s"
      >
        <Fade in={!isSidebarTransitioning} duration={0.3}>
          <Box>
            <Image
              src={isSidebarCollapsed ? icon : logo}
              alt="Logo"
              mb={4}
              w={isSidebarCollapsed ? "40px" : "200px"}
              mx={isSidebarCollapsed ? "auto" : 0}
            />
            {!isSidebarCollapsed && (
              <Fade in={!isSidebarCollapsed && showText} duration={0.3}>
                <Text mb={8} color="gray.500">
                  {userRole.toUpperCase()} DASHBOARD
                </Text>
              </Fade>
            )}
            <VStack spacing={2} align="stretch">
              {sidebarButtons[userRole]?.map((button) => (
                <Button
                  key={button.route}
                  onClick={() => navigate(`/dashboard/${button.route}`)}
                  variant="ghost"
                  color="white"
                  leftIcon={<button.icon size={24} />}
                  justifyContent={isSidebarCollapsed ? "center" : "flex-start"}
                  borderWidth="1px"
                  borderColor={isRouteActive(button.route) ? "#4d6d8b" : "#001c36"}
                  bg={isRouteActive(button.route) ? "#002c55" : "transparent"}
                  _hover={{
                    bg: "#002c55",
                    borderColor: "#4d6d8b",
                    borderWidth: "1px",
                  }}
                  fontSize={18}
                  py={6}
                  px={isSidebarCollapsed ? 2 : 6}
                  pl="16px"
                >
                  {!isSidebarCollapsed && button.label}
                </Button>
              ))}
              <Button
                aria-label="Toggle Sidebar"
                onClick={toggleSidebar}
                color="white"
                bg="#001c36"
                borderWidth="1px"
                borderColor="#001c36"
                _hover={{
                  bg: "#002c55",
                  borderColor: "#4d6d8b",
                  borderWidth: "1px",
                }}
                mt={2}
                alignSelf="flex-start"
                leftIcon={isSidebarCollapsed ? <FiChevronRight size={24} /> : <FiChevronLeft size={24} />}
                px={1}
                py="20px"
                pl="14px"
              >
                {isSidebarCollapsed ? null : ""}
              </Button>
            </VStack>
          </Box>
        </Fade>
        <Fade in={!isSidebarTransitioning} duration={0.3}>
          <Box>
            <Button
              onClick={() => navigate(`/dashboard/contact`)}
              variant="ghost"
              color="white"
              leftIcon={<FiMessageSquare size={24} />}
              justifyContent={isSidebarCollapsed ? "center" : "flex-start"}
              borderWidth="1px"
              borderColor={isRouteActive("contact") ? "#4d6d8b" : "#001c36"}
              bg={isRouteActive("contact") ? "#002c55" : "transparent"}
              _hover={{
                bg: "#002c55",
                borderColor: "#4d6d8b",
                borderWidth: "1px",
              }}
              fontSize={18}
              py={6}
              px={isSidebarCollapsed ? 2 : 6}
              pl="16px"
              mb={2}
              width="100%"
            >
              {!isSidebarCollapsed && "Contact"}
            </Button>
            <Button
              variant="ghost"
              color="white"
              leftIcon={<FiLogOut size={24} />}
              justifyContent={isSidebarCollapsed ? "center" : "flex-start"}
              fontSize={18}
              py={6}
              px={isSidebarCollapsed ? 2 : 6}
              bg="#001c36"
              _hover={{ color: "red", bg: "#001c36" }}
              _active={{ bg: "#001c36" }}
              onClick={handleLogout}
              pl="16px"
            >
              {!isSidebarCollapsed && "Logout"}
            </Button>
            {!isSidebarCollapsed && (
              <Fade in={!isSidebarCollapsed && showText} duration={0.3}>
                <>
                  <Divider my={2} color="gray.500" />
                  <Box textAlign="center">
                    <Text color="gray.500">@ EvalHero. All rights reserved.</Text>
                  </Box>
                </>
              </Fade>
            )}
          </Box>
        </Fade>
      </Box>
      <Box flex={1} ml={isSidebarCollapsed ? "100px" : "280px"} transition="margin-left 0.3s">
        <Box borderBottomWidth="1px" borderBottomColor="gray.300" p={3}>
          <Flex justify="space-between">
            <Breadcrumbs /> {/* Add Breadcrumbs component here */}
            <Text fontSize="xl" fontWeight="bold">
              {userDetails.firstName} {userDetails.lastName}
            </Text>
          </Flex>
        </Box>
        <Box p={8}>
          <Outlet />
        </Box>
      </Box>
    </Flex>
  );
};

export default Main;
