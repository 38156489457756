import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Input,
  VStack,
  HStack,
  Avatar,
  Text,
  Heading,
  Spinner,
  Center,
  Image,
} from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import axios from "axios";
import { useAuth } from "../../../contexts/AuthContext";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../../Firebase";
import protocoluser from "../../../assets/protocol-user.jpg";
import tempImage from "../../../assets/temp.png";
import FileList from "./FileList"; // Make sure the path is correct

const AssistantChat = () => {
  const { currentUser, userDetails } = useAuth();
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [threadId, setThreadId] = useState(null);
  const [assistantId, setAssistantId] = useState(null);
  const [isCreatingThread, setIsCreatingThread] = useState(false);
  const [loading, setLoading] = useState(true);
  const [vectorStoreId, setVectorStoreId] = useState(null);

  const API_KEY = "sk-proj-Tp6FFkSvCegPaNHP53TBT3BlbkFJEonSWHHvtVsuJI4IuaDY";
  const BASE_URL = "https://api.openai.com/v1";

  const createThread = useCallback(
    async (vectorStoreId) => {
      if (isCreatingThread || !vectorStoreId) return;

      setIsCreatingThread(true);
      try {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        const response = await axios.post(
          `${BASE_URL}/threads`,
          {
            tool_resources: {
              file_search: {
                vector_store_ids: [vectorStoreId],
              },
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${API_KEY}`,
              "OpenAI-Beta": "assistants=v2",
            },
          }
        );
        setThreadId(response.data.id);
        console.log(response);
        setLoading(false);
      } catch (error) {
        console.error("Error creating thread:", error);
        setLoading(false);
      } finally {
        setIsCreatingThread(false);
      }
    },
    [API_KEY, BASE_URL, isCreatingThread]
  );

  const fetchAssistantId = useCallback(async () => {
    if (!userDetails.organizationId || assistantId) return;

    const orgRef = doc(db, "org", userDetails.organizationId);
    const orgDoc = await getDoc(orgRef);

    if (orgDoc.exists()) {
      const orgData = orgDoc.data();
      if (orgData.assistantId) {
        setAssistantId(orgData.assistantId);
        setVectorStoreId(orgData.vectorStoreId);
        console.log("Existing Assistant ID:", orgData.assistantId);
        console.log("Existing Vector Store ID:", orgData.vectorStoreId);
        await createThread(orgData.vectorStoreId);
      } else {
        // Create vector store
        const vectorStoreResponse = await axios.post(
          `${BASE_URL}/vector_stores`,
          {
            name: "EvalHero Dev",
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${API_KEY}`,
              "OpenAI-Beta": "assistants=v2",
            },
          }
        );
        const newVectorStoreId = vectorStoreResponse.data.id;
        console.log("New Vector Store ID:", newVectorStoreId);
        await setDoc(
          orgRef,
          { vectorStoreId: newVectorStoreId },
          { merge: true }
        );

        // New Assistant
        const assistantResponse = await axios.post(
          `${BASE_URL}/assistants`,
          {
            name: "My Assistant",
            instructions: "You are a helpful assistant.",
            model: "gpt-3.5-turbo-0125",
            tools: [
              {
                type: "file_search",
              },
            ],
            tool_resources: {
              file_search: {
                vector_store_ids: [newVectorStoreId],
              },
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${API_KEY}`,
              "OpenAI-Beta": "assistants=v2",
            },
          }
        );
        const newAssistantId = assistantResponse.data.id;
        setAssistantId(newAssistantId);
        setVectorStoreId(newVectorStoreId);
        console.log("New Assistant ID:", newAssistantId);
        await setDoc(orgRef, { assistantId: newAssistantId }, { merge: true });
        await createThread(newVectorStoreId);
      }
    }
  }, [
    API_KEY,
    BASE_URL,
    userDetails.organizationId,
    assistantId,
    createThread,
  ]);

  const addMessage = async (content) => {
    await axios.post(
      `${BASE_URL}/threads/${threadId}/messages`,
      {
        role: "user",
        content: content,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${API_KEY}`,
          "OpenAI-Beta": "assistants=v2",
        },
      }
    );
    runAssistant();
  };

  const runAssistant = async () => {
    await axios.post(
      `${BASE_URL}/threads/${threadId}/runs`,
      {
        assistant_id: assistantId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${API_KEY}`,
          "OpenAI-Beta": "assistants=v2",
        },
      }
    );
    fetchMessages();
  };

  const fetchMessages = useCallback(async () => {
    const response = await axios.get(
      `${BASE_URL}/threads/${threadId}/messages`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${API_KEY}`,
          "OpenAI-Beta": "assistants=v2",
        },
      }
    );
    setMessages(response.data.data);
  }, [API_KEY, BASE_URL, threadId]);

  useEffect(() => {
    if (currentUser && userDetails.organizationId && !assistantId) {
      const interval = setInterval(() => {
        fetchAssistantId();
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [currentUser, userDetails.organizationId, assistantId, fetchAssistantId]);

  useEffect(() => {
    if (threadId) {
      const interval = setInterval(() => {
        fetchMessages();
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [threadId, fetchMessages]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && input.trim()) {
      addMessage(input);
      setInput("");
    }
  };

  if (loading) {
    return (
      <Center h="calc(100vh - 120px)">
        <Spinner size="xl" />
      </Center>
    );
  }

  return (
    <Box display="flex" h="calc(100vh - 120px)">
      <Box flex="1" display="flex" flexDirection="column">
        <Heading mb={4}>Protocols</Heading>
        <VStack spacing={4} align="stretch" flex="1" overflowY="auto">
          {messages.length === 0 ? (
            <Center flex="1" flexDirection="column">
              <Image src={tempImage} alt="Temporary Image" mb={4} w="100px" />
              <Text fontSize="lg" color="gray.500">
                Ask a question below
              </Text>
            </Center>
          ) : (
            <VStack
              align="stretch"
              spacing={3}
              border="1px solid"
              borderColor="#e6e6e6"
              p={4}
              borderRadius="md"
              flex="1"
            >
              {messages
                .slice()
                .reverse()
                .map((msg, idx) => (
                  <HStack
                    key={idx}
                    alignSelf={msg.role === "user" ? "flex-end" : "flex-start"}
                  >
                    {msg.role === "assistant" && (
                      <Avatar name="Assistant" src={protocoluser} />
                    )}
                    <Box
                      p={2}
                      bg={msg.role === "user" ? "blue.100" : "#e6e6e6"}
                      borderRadius="md"
                    >
                      <Text>
                        {msg.content.map((c) => c.text.value).join(" ")}
                      </Text>
                    </Box>
                    {msg.role === "user" && (
                      <Avatar name="User" src="/user-icon.png" />
                    )}
                  </HStack>
                ))}
            </VStack>
          )}
        </VStack>
        <HStack spacing={2} mt={4}>
          <Input
            placeholder="Search protocols"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={handleKeyDown}
            flex="1"
            size="lg"
            border="1px solid"
            borderColor="#e6e6e6"
          />
          <Button
            onClick={() => {
              addMessage(input);
              setInput("");
            }}
            colorScheme="blue"
            size="lg"
            rightIcon={<ArrowForwardIcon />}
          >
            Send
          </Button>
        </HStack>
      </Box>
      {vectorStoreId && (
        <FileList vectorStoreId={vectorStoreId} API_KEY={API_KEY} />
      )}
    </Box>
  );
};

export default AssistantChat;
