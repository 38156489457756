import React, { useState, useEffect } from "react";
import {
  VStack,
  Input,
  Textarea,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Checkbox,
  HStack,
  Spacer,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";

const MultiLineEditor = ({
  isOpen,
  onClose,
  onSave,
  onDelete,
  initialData,
}) => {
  const [label, setLabel] = useState("");
  const [placeholder, setPlaceholder] = useState("");
  const [isRequired, setIsRequired] = useState(false);

  useEffect(() => {
    if (initialData) {
      setLabel(initialData.label || "");
      setPlaceholder(initialData.placeholder || "");
      setIsRequired(initialData.isRequired || false);
    }
  }, [initialData]);

  const handleSave = () => {
    onSave({ type: "multi-line", label, placeholder, isRequired });
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Multi Line Field</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} align="stretch">
            <FormControl>
              <FormLabel>Field Label</FormLabel>
              <Input
                placeholder="Enter field label"
                value={label}
                onChange={(e) => setLabel(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Placeholder Text</FormLabel>
              <Textarea
                placeholder="Enter placeholder text"
                value={placeholder}
                onChange={(e) => setPlaceholder(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <HStack>
                <Checkbox
                  isChecked={isRequired}
                  onChange={(e) => setIsRequired(e.target.checked)}
                >
                  Required field
                </Checkbox>
                <Spacer />
              </HStack>
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="red" mr={3} onClick={onDelete}>
            Delete
          </Button>
          <Button colorScheme="blue" mr={3} onClick={handleSave}>
            Save
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default MultiLineEditor;