import React from 'react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { IconButton, Tooltip } from '@chakra-ui/react';
import { DownloadIcon } from '@chakra-ui/icons';
import fontData from '../../../fonts/Reddit_Sans/static/RedditSans-Medium.ttf';
import logo from '../../../assets/whitelogo.png';

const ReportPDF = ({ report, formatDate }) => {
  const generatePDF = () => {
    const doc = new jsPDF();

    // Add custom font
    doc.addFont(fontData, 'RedditSans', 'normal');
    doc.setFont('RedditSans');

    // Add logo with fixed dimensions
    const logoWidth = 40;
    const logoHeight = 6;
    doc.addImage(logo, 'PNG', 10, 10, logoWidth, logoHeight);

    // Title
    doc.setFontSize(24);
    doc.setTextColor(41, 128, 185); // Blue color
    doc.text(report.evaluationName || 'Evaluation Report', 60, 25);

    // Subtitle
    doc.setFontSize(14);
    doc.setTextColor(100);
    doc.text(report.evaluationDescription || '', 60, 35, { maxWidth: 130 });

    // Information box
    doc.setDrawColor(200);
    doc.setFillColor(240);
    doc.roundedRect(10, 50, 190, 40, 3, 3, 'FD');

    doc.setFontSize(10);
    doc.setTextColor(60);
    const infoY = 60;
    doc.text(`Status: ${report.status || 'N/A'}`, 15, infoY);
    doc.text(`Submitted At: ${formatDate(report.timeSubmitted) || 'N/A'}`, 15, infoY + 10);
    doc.text(`Submitted By: ${report.submittedBy?.name || 'N/A'}`, 15, infoY + 20);
    doc.text(`Submitted For: ${report.submittedFor?.name || 'N/A'}`, 110, infoY);
    doc.text(`Verified On: ${report.verifiedOn ? formatDate(report.verifiedOn) : 'N/A'}`, 110, infoY + 10);

    // Overall Results
    doc.setFontSize(16);
    doc.setTextColor(41, 128, 185);
    doc.text('Overall Results', 10, 105);

    doc.setFontSize(12);
    doc.setTextColor(60);
    doc.text(`Total Points: ${report.earnedPoints || 0} / ${report.totalFormPoints || 0}`, 15, 115);
    doc.text(`Pass/Fail Count: ${report.passedCount || 0} / ${report.totalPassFailCount || 0}`, 15, 125);

    const overallResult = (report.earnedPoints >= report.totalFormPoints && report.passedCount >= report.totalPassFailCount) ? 'PASS' : 'FAIL';
    doc.setFontSize(14);
    doc.setTextColor(overallResult === 'PASS' ? 46 : 231, overallResult === 'PASS' ? 204 : 76, overallResult === 'PASS' ? 113 : 60);
    doc.text(`Overall Result: ${overallResult}`, 15, 135);

    // Form Responses
    doc.setFontSize(16);
    doc.setTextColor(41, 128, 185);
    doc.text('Form Responses', 10, 155);

    const tableData = (report.formResponses || []).map(response => [
      response.label || '',
      Array.isArray(response.answer) ? response.answer.join(', ') : (response.answer || 'N/A'),
      response.answerDetails ? (response.answerDetails.result || `${response.answerDetails.points || 0}/${response.answerDetails.maxPoints || 0}`) : 'N/A'
    ]);

    doc.autoTable({
      startY: 160,
      head: [['Question', 'Answer', 'Result']],
      body: tableData,
      theme: 'striped',
      headStyles: { fillColor: [41, 128, 185], textColor: 255 },
      alternateRowStyles: { fillColor: [240, 240, 240] },
      margin: { top: 160 },
      styles: { font: 'RedditSans' },
    });

    // Footer
    const pageCount = doc.internal.getNumberOfPages();
    doc.setFontSize(10);
    doc.setTextColor(150);
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(`Page ${i} of ${pageCount}`, doc.internal.pageSize.width / 2, doc.internal.pageSize.height - 10, { align: 'center' });
    }

    const fileName = `${(report.evaluationName || 'Report').replace(/\s+/g, '_')}_${formatDate(report.timeSubmitted || new Date()).replace(/[/, :]/g, '_')}.pdf`;
    doc.save(fileName);
  };

  return (
    <Tooltip label="Download Report" aria-label="Download Report">
      <IconButton
        colorScheme="blue"
        icon={<DownloadIcon />}
        onClick={generatePDF}
      />
    </Tooltip>
  );
};

export default ReportPDF;
