import React, { useState, useMemo } from 'react';
import {
  Box, Text, Button, Popover, PopoverTrigger, PopoverContent, PopoverHeader, PopoverBody, PopoverArrow, PopoverCloseButton,
  SimpleGrid, useDisclosure, Flex, IconButton
} from '@chakra-ui/react';
import { FaCalendarAlt, FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const DateRangeSelector = ({ onDateRangeChange }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const { isOpen, onOpen, onClose } = useDisclosure();

  const formatDate = (date) => {
    if (!date) return '';
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  const formatButtonText = (start, end) => {
    if (start && end) {
      return `${formatDate(start)} - ${formatDate(end)}`;
    } else if (start) {
      return `${formatDate(start)} - Select End Date`;
    } else {
      return 'Select Date Range';
    }
  };

  const generateCalendarDays = useMemo(() => {
    const year = currentMonth.getFullYear();
    const month = currentMonth.getMonth();
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    
    const daysArray = [];
    const startPadding = firstDay.getDay();
    
    for (let i = 0; i < startPadding; i++) {
      daysArray.push(null);
    }
    
    for (let i = 1; i <= lastDay.getDate(); i++) {
      daysArray.push(new Date(year, month, i));
    }
    
    return daysArray;
  }, [currentMonth]);

  const handleDateSelect = (date) => {
    if (!startDate || (startDate && endDate)) {
      setStartDate(date);
      setEndDate(null);
    } else if (date < startDate) {
      setEndDate(startDate);
      setStartDate(date);
    } else {
      setEndDate(date);
    }

    if (date && (startDate || endDate)) {
      const start = startDate || date;
      const end = endDate || date;
      onDateRangeChange(start.toISOString(), end.toISOString());
    }
  };

  const handlePrevMonth = () => {
    setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, 1));
  };

  const handleNextMonth = () => {
    setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1));
  };

  const isDateInRange = (date) => {
    return date && startDate && date >= startDate && date <= (endDate || startDate);
  };

  return (
    <Box mb={4}>
      <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} placement="bottom-start">
        <PopoverTrigger>
          <Button
            width="300px"
            height="50px"
            borderRadius="8px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            leftIcon={<FaCalendarAlt />}
          >
            {formatButtonText(startDate, endDate)}
          </Button>
        </PopoverTrigger>
        <PopoverContent maxH="400px" overflowY="auto">
          <Box position="sticky" top={0} bg="white" zIndex={1}>
            <PopoverArrow />
            <PopoverCloseButton mt={1} />
            <PopoverHeader fontWeight="bold">Select Date Range</PopoverHeader>
          </Box>
          <PopoverBody>
            <Flex justifyContent="space-between" alignItems="center" mb={2}>
              <IconButton
                aria-label="Previous month"
                icon={<FaArrowLeft />}
                onClick={handlePrevMonth}
              />
              <Text fontWeight="bold">{currentMonth.toLocaleString('default', { month: 'long', year: 'numeric' })}</Text>
              <IconButton
                aria-label="Next month"
                icon={<FaArrowRight />}
                onClick={handleNextMonth}
              />
            </Flex>
            <SimpleGrid columns={7} spacing={2}>
              {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
                <Box key={day} textAlign="center" fontWeight="bold">{day}</Box>
              ))}
              {generateCalendarDays.map((date, index) => (
                <Button
                  key={index}
                  onClick={() => date && handleDateSelect(date)}
                  variant={date && isDateInRange(date) ? "solid" : "outline"}
                  colorScheme={date && isDateInRange(date) ? "blue" : "gray"}
                  size="sm"
                  isDisabled={!date}
                >
                  {date ? date.getDate() : ''}
                </Button>
              ))}
            </SimpleGrid>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default DateRangeSelector;