import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Text,
  Wrap,
  WrapItem,
  Tag,
  HStack,
  Button,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useParams, useNavigate } from "react-router-dom";
import { doc, getDoc, addDoc, collection } from "firebase/firestore";
import { db } from "../../../Firebase";
import StaffMemberSelector from "./modules/StaffMemberSelector";
import { useAuth } from "../../../contexts/AuthContext";
import * as Icons from 'react-icons/fa';
import FormField from "./modules/FormField";

const EvaluationsForm = () => {
  const { evaluationId } = useParams();
  const { userDetails } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();
  const [evaluation, setEvaluation] = useState(null);
  const [organizationId, setOrganizationId] = useState("");
  const [staffMembers, setStaffMembers] = useState([]);
  const [selectedStaffMember, setSelectedStaffMember] = useState(null);
  const [formValues, setFormValues] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (userDetails.organizationId) {
      setOrganizationId(userDetails.organizationId);
    }
  }, [userDetails]);

  useEffect(() => {
    const fetchStaffMembers = async () => {
      try {
        if (organizationId) {
          const orgDocRef = doc(db, "org", organizationId);
          const orgDocSnapshot = await getDoc(orgDocRef);
          if (orgDocSnapshot.exists()) {
            const orgData = orgDocSnapshot.data();
            const staffData = orgData.staff || [];

            const staffMembersWithNames = await Promise.all(
              staffData.map(async (staff) => {
                const staffDocRef = doc(db, "users", staff.userId);
                const staffDocSnapshot = await getDoc(staffDocRef);
                if (staffDocSnapshot.exists()) {
                  const staffData = staffDocSnapshot.data();
                  return {
                    ...staff,
                    firstName: staffData.firstName,
                    lastName: staffData.lastName,
                    profilePicture: staffData.profilePicture,
                    email: staffData.email,
                  };
                }
                return staff;
              })
            );

            setStaffMembers(staffMembersWithNames);
          } else {
            console.error("Organization document not found");
          }
        }
      } catch (error) {
        console.error("Error fetching staff members:", error);
      }
    };

    fetchStaffMembers();
  }, [organizationId]);

  useEffect(() => {
    const fetchEvaluation = async () => {
      try {
        if (organizationId) {
          const evaluationRef = doc(db, "org", organizationId, "evaluations", evaluationId);
          const evaluationSnapshot = await getDoc(evaluationRef);
          if (evaluationSnapshot.exists()) {
            setEvaluation(evaluationSnapshot.data());
            initializeFormValues(evaluationSnapshot.data().fields);
          } else {
            console.error("Evaluation not found");
          }
        }
      } catch (error) {
        console.error("Error fetching evaluation:", error);
      }
    };

    fetchEvaluation();
  }, [evaluationId, organizationId]);

  const initializeFormValues = (fields) => {
    const initialValues = {};
    fields.forEach(field => {
      initialValues[field.label] = field.type === 'multi-choice' && field.grading !== 'points' && field.grading !== 'passfail' ? [] : "";
    });
    setFormValues(initialValues);
  };

  const handleInputChange = (fieldLabel, value) => {
    setFormValues(prevValues => ({
      ...prevValues,
      [fieldLabel]: value
    }));
  };

  const handleMultiChoiceSelection = (field, optionText) => {
    if (field.grading === "points" || field.grading === "passfail") {
      handleInputChange(field.label, formValues[field.label] === optionText ? "" : optionText);
    } else {
      const currentValues = formValues[field.label] || [];
      const updatedValues = currentValues.includes(optionText)
        ? currentValues.filter(value => value !== optionText)
        : [...currentValues, optionText];
      handleInputChange(field.label, updatedValues);
    }
  };

  const isOptionSelected = (field, optionText) => {
    const fieldValue = formValues[field.label];
    if (field.grading === "points" || field.grading === "passfail") {
      return fieldValue === optionText;
    } else {
      return Array.isArray(fieldValue) && fieldValue.includes(optionText);
    }
  };

  useEffect(() => {
    if (evaluation) {
      const requiredFields = evaluation.fields.filter(field => field.isRequired);
      const allRequiredFieldsFilled = requiredFields.every(field => {
        const value = formValues[field.label];
        return Array.isArray(value) ? value.length > 0 : value?.trim() !== "";
      });
      setIsFormValid(allRequiredFieldsFilled && selectedStaffMember !== null);
    }
  }, [formValues, evaluation, selectedStaffMember]);

  const getAnswerDetails = (field, answer) => {
    if (answer === undefined || answer === null) {
      return null;
    }

    switch (field.type) {
      case 'multi-choice':
        if (field.grading === 'points') {
          const selectedOption = field.options.find(option => option.text === answer);
          return selectedOption ? { points: selectedOption.value, maxPoints: field.totalPoints } : null;
        } else if (field.grading === 'passfail') {
          const selectedOption = field.options.find(option => option.text === answer);
          return selectedOption ? { result: selectedOption.value } : null;
        } else {
          return Array.isArray(answer) ? answer : [answer];
        }
      case 'single-line':
      case 'multi-line':
        return answer;
      default:
        return null;
    }
  };

  const handleSubmit = async () => {
    try {
      if (!isFormValid) {
        console.error("Form is not valid");
        return;
      }

      setIsSubmitting(true);

      if (!selectedStaffMember || !selectedStaffMember.email) {
        throw new Error("Selected staff member or email is missing");
      }

      const reportData = {
        totalFormPoints: evaluation.totalFormPoints || "",
        totalPassFailCount: evaluation.totalPassFailCount || "",
        evaluationName: evaluation.name || "",
        evaluationDescription: evaluation.description || "",
        evaluationIcon: evaluation.icon || "",
        evaluationTags: evaluation.tags || [],
        submittedBy: {
          id: userDetails.uid || "",
          name: `${userDetails.firstName || ""} ${userDetails.lastName || ""}`.trim(),
          email: userDetails.email || "",
        },
        submittedFor: {
          id: selectedStaffMember.userId || "",
          name: `${selectedStaffMember.firstName || ""} ${selectedStaffMember.lastName || ""}`.trim(),
          email: selectedStaffMember.email || "",
        },
        timeSubmitted: new Date().toISOString(),
        status: "pending",
        formFields: evaluation.fields,
        formResponses: evaluation.fields.map(field => {
          const answer = formValues[field.label];
          const answerDetails = getAnswerDetails(field, answer);
          return {
            label: field.label || "",
            answer: answer || null,
            answerDetails: answerDetails || null,
          };
        }),
      };

      // Remove any properties with undefined values
      const cleanReportData = JSON.parse(JSON.stringify(reportData));

      const reportsCollectionRef = collection(db, "org", organizationId, "reports");
      const docRef = await addDoc(reportsCollectionRef, cleanReportData);

      toast({
        title: "Report submitted successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      navigate(`/dashboard/reports/${docRef.id}`);
    } catch (error) {
      console.error("Error submitting report:", error);
      toast({
        title: "Error submitting report",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!evaluation) {
    return <Text>Loading...</Text>;
  }

  const IconComponent = Icons[evaluation.icon] || Icons.FaQuestionCircle;

  return (
    <Box maxWidth="700px" mx="auto">
      <HStack alignItems="center" mb={4}>
        <IconComponent size="3em"/>
        <Heading fontSize="4xl" fontWeight="bold" ml={2}>
          {evaluation.name}
        </Heading>
      </HStack>
      <Text fontSize="lg" mb={4}>
        {evaluation.description}
      </Text>
      {evaluation.tags && (
        <Wrap spacing={2} mb={4}>
          {evaluation.tags.map((tag, index) => (
            <WrapItem key={index}>
              <Tag>{tag}</Tag>
            </WrapItem>
          ))}
        </Wrap>
      )}
      <StaffMemberSelector
        staffMembers={staffMembers}
        selectedStaffMember={selectedStaffMember}
        onStaffMemberChange={setSelectedStaffMember}
      />
      <VStack spacing={4} align="stretch" mt={6}>
        {evaluation.fields.map((field, index) => (
          <FormField
            key={index}
            field={field}
            formValues={formValues}
            handleInputChange={handleInputChange}
            handleMultiChoiceSelection={handleMultiChoiceSelection}
            isOptionSelected={isOptionSelected}
          />
        ))}
      </VStack>
      <Button
        mt={6}
        colorScheme="blue"
        isDisabled={!isFormValid || isSubmitting}
        onClick={handleSubmit}
        w="100%"
        isLoading={isSubmitting}
        loadingText="Submitting"
      >
        Submit
      </Button>
    </Box>
  );
};

export default EvaluationsForm;