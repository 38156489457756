import React, { useState, useEffect } from "react";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { Box, Heading, Flex, Avatar, Text, Alert, AlertIcon, Spinner } from "@chakra-ui/react";
import { db } from "../../../Firebase";
import { useAuth } from "../../../contexts/AuthContext";

function MyTrainer() {
  const { currentUser, userDetails } = useAuth();
  const [trainer, setTrainer] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let unsubscribeOrg = () => {};

    const fetchTrainer = async () => {
      setLoading(true);
      setError(null);

      if (!currentUser || !userDetails || !userDetails.organizationId) {
        setError("User or organization information is missing");
        setLoading(false);
        return;
      }

      const organizationId = userDetails.organizationId;

      try {
        const organizationDocRef = doc(db, "org", organizationId);
        unsubscribeOrg = onSnapshot(organizationDocRef, async (organizationDocSnapshot) => {
          if (!organizationDocSnapshot.exists()) {
            setError("Organization document not found");
            setTrainer(null);
            setLoading(false);
            return;
          }

          const organizationData = organizationDocSnapshot.data();
          if (!organizationData || !organizationData.staff) {
            setError("Organization data or staff not found");
            setTrainer(null);
            setLoading(false);
            return;
          }

          const staff = organizationData.staff;
          const trainee = staff.find((member) => member.userId === currentUser.uid && member.role === "trainee");

          if (!trainee || !trainee.trainers || trainee.trainers.length === 0) {
            setError("Current user does not have a trainer or is not a trainee");
            setTrainer(null);
            setLoading(false);
            return;
          }

          const trainerId = trainee.trainers[0]; // Assuming each trainee has one trainer
          const trainerDocRef = doc(db, "users", trainerId);
          const trainerDocSnapshot = await getDoc(trainerDocRef);

          if (!trainerDocSnapshot.exists()) {
            setError("Trainer document not found");
            setTrainer(null);
            setLoading(false);
            return;
          }

          const trainerData = trainerDocSnapshot.data();
          setTrainer({
            ...trainerData,
            userId: trainerId,
          });
          setLoading(false);
        });
      } catch (error) {
        console.error("Error fetching trainer:", error);
        setError(`Error fetching trainer: ${error.message}`);
        setTrainer(null);
        setLoading(false);
      }
    };

    fetchTrainer();

    return () => unsubscribeOrg();
  }, [currentUser, userDetails]);

  return (
    <Box>
      <Heading as="h3" size="lg" mb={4}>
        My Trainer
      </Heading>
      {loading ? (
        <Flex justifyContent="center" alignItems="center" height="200px">
          <Spinner size="xl" />
        </Flex>
      ) : error ? (
        <Alert status="error" mb={4}>
          <AlertIcon />
          {error}
        </Alert>
      ) : trainer ? (
        <Flex alignItems="center" p={4} bg="gray.100" borderRadius="md" flexDirection="column">
          <Avatar
            size="lg"
            src={trainer.profilePictureURL}
            name={`${trainer.firstName} ${trainer.lastName}`}
            mb={4}
          />
          <Text fontWeight="bold" fontSize="lg">
            {trainer.firstName} {trainer.lastName}
          </Text>
          <Text>{trainer.email}</Text>
        </Flex>
      ) : (
        <Text>No trainer assigned.</Text>
      )}
    </Box>
  );
}

export default MyTrainer;