// Reports.jsx
import React, { useEffect, useState } from 'react';
import {
  Box, Heading, VStack, Text, Button, Badge, HStack, Wrap, WrapItem, Tag, FormControl, Input,
  Stat, StatLabel, StatNumber, StatGroup
} from '@chakra-ui/react';
import { collection, getDocs, query, orderBy, where } from 'firebase/firestore';
import { db } from "../../../Firebase";
import { useAuth } from "../../../contexts/AuthContext";
import { useNavigate } from 'react-router-dom';
import DateRangeSelector from '../utils/DateRangeSelector';

const Reports = () => {
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const { userDetails } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchReports = async () => {
      if (userDetails?.organizationId) {
        try {
          const reportsRef = collection(db, 'org', userDetails.organizationId, 'reports');
          let q;

          if (startDate && endDate) {
            q = query(
              reportsRef,
              where('timeSubmitted', '>=', startDate),
              where('timeSubmitted', '<=', endDate),
              orderBy('timeSubmitted', 'desc')
            );
          } else {
            q = query(
              reportsRef,
              orderBy('timeSubmitted', 'desc')
            );
          }
          
          const querySnapshot = await getDocs(q);
          
          const fetchedReports = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));

          const filteredReports = fetchedReports.filter(report => 
            report.submittedBy.id === userDetails.uid || report.submittedFor.id === userDetails.uid
          );

          const reportsWithStats = filteredReports.map(report => ({
            ...report,
            stats: calculateReportStats(report)
          }));

          setReports(reportsWithStats);
        } catch (error) {
          console.error("Error fetching reports:", error);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    fetchReports();
  }, [userDetails, startDate, endDate]);

  const calculateReportStats = (report) => {
    let totalPoints = 0;
    let earnedPoints = 0;
    let totalPassFail = 0;
    let passedCount = 0;

    report.formResponses.forEach(response => {
      if (response.answerDetails) {
        if (typeof response.answerDetails === 'object') {
          if ('points' in response.answerDetails) {
            totalPoints += response.answerDetails.maxPoints || 0;
            earnedPoints += response.answerDetails.points || 0;
          }
          if ('result' in response.answerDetails) {
            totalPassFail++;
            if (response.answerDetails.result === 'pass') {
              passedCount++;
            }
          }
        }
      }
    });

    const pointsPercentage = totalPoints > 0 ? (earnedPoints / totalPoints) * 100 : 100;
    const allPassFailPassed = totalPassFail === passedCount;
    const overallPass = allPassFailPassed && pointsPercentage >= 70;

    return {
      totalPoints,
      earnedPoints,
      totalPassFail,
      passedCount,
      overallPass
    };
  };

  const handleDateRangeChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  const formatDate = (date) => {
    return date ? new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) : '';
  };

  const handleViewDetails = (reportId) => {
    navigate(`/dashboard/reports/${reportId}`);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleTagClick = (tag) => {
    setSelectedTags((prevTags) =>
      prevTags.includes(tag) ? prevTags.filter((t) => t !== tag) : [...prevTags, tag]
    );
  };

  const filteredReports = reports.filter((report) =>
    (report.evaluationName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (report.evaluationTags && report.evaluationTags.some((tag) => tag.toLowerCase().includes(searchTerm.toLowerCase())))) &&
    (selectedTags.length === 0 || (report.evaluationTags && selectedTags.every((tag) => report.evaluationTags.includes(tag))))
  );

  const uniqueTags = Array.from(new Set(reports.flatMap((report) => report.evaluationTags || [])));

  return (
    <Box>
      <Heading fontSize="4xl" fontWeight="bold" mb={4}>
        Reports
      </Heading>
      <HStack>
        <DateRangeSelector onDateRangeChange={handleDateRangeChange} />
      </HStack>
      <Wrap spacing={4} mb={4}>
        {uniqueTags.map((tag) => (
          <WrapItem key={tag}>
            <Button
              onClick={() => handleTagClick(tag)}
              colorScheme={selectedTags.includes(tag) ? 'blue' : 'gray'}
            >
              {tag}
            </Button>
          </WrapItem>
        ))}
      </Wrap>
      <FormControl mb={4}>
        <Input
          id="search"
          name="search"
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder="Search by evaluation name or tags"
        />
      </FormControl>
      {loading ? (
        <Text>Loading reports...</Text>
      ) : filteredReports.length === 0 ? (
        <Text>No reports found{startDate && endDate ? " for the selected date range" : ""}.</Text>
      ) : (
        <VStack spacing={4} align="stretch">
          {filteredReports.map((report) => (
            <Box key={report.id} p={4} borderWidth={1} borderRadius="md">
              <Heading size="md">{report.evaluationName}</Heading>
              {report.evaluationTags && (
                <Wrap spacing={2} py={1}>
                  {report.evaluationTags.map((tag, index) => (
                    <WrapItem key={index}>
                      <Tag>{tag}</Tag>
                    </WrapItem>
                  ))}
                </Wrap>
              )}
              <HStack>
                <Text>Submitted At: {formatDate(report.timeSubmitted)}</Text>
                <Badge colorScheme={report.status === 'verified' ? 'green' : 'orange'}>
                  Status: {report.status || 'Pending'}
                </Badge>
                <Badge colorScheme={report.stats.overallPass ? 'green' : 'red'}>
                  Overall: {report.stats.overallPass ? 'PASS' : 'FAIL'}
                </Badge>
              </HStack>
              <Text>Submitted By: {report.submittedBy.name}</Text>
              <Text>Submitted For: {report.submittedFor.name}</Text>
              
              <StatGroup mt={2}>
                <Stat>
                  <StatLabel>Points</StatLabel>
                  <StatNumber>{report.stats.earnedPoints} / {report.stats.totalPoints}</StatNumber>
                </Stat>
                <Stat>
                  <StatLabel>Pass/Fail Questions</StatLabel>
                  <StatNumber>{report.stats.passedCount} / {report.stats.totalPassFail}</StatNumber>
                </Stat>
              </StatGroup>
              
              <Button 
                size="sm" 
                colorScheme="blue" 
                onClick={() => handleViewDetails(report.id)}
                mt={2}
              >
                View Details
              </Button>
            </Box>
          ))}
        </VStack>
      )}
    </Box>
  );
};

export default Reports;