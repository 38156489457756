import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAg2tK0ujmoHzw0_znzEjrz9Ba7mRgupnE",
  authDomain: "evalherodev.firebaseapp.com",
  projectId: "evalherodev",
  storageBucket: "evalherodev.appspot.com",
  messagingSenderId: "1916533090",
  appId: "1:1916533090:web:43d1f191ad0657e2dd943b"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { app, auth, db, storage };