import React, { useState, useEffect } from "react";
import { doc, getDoc, onSnapshot, updateDoc } from "firebase/firestore";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Heading,
  Switch,
  Image,
  Avatar,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Select,
  Flex,
  Input,
} from "@chakra-ui/react";
import { db, auth } from "../../../Firebase";
import { useNavigate } from 'react-router-dom';

function StaffAll() {
  const [staffMembers, setStaffMembers] = useState([]);
  const [filteredStaffMembers, setFilteredStaffMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedStaffMember, setSelectedStaffMember] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [availableDepartments, setAvailableDepartments] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const fetchStaffMembers = async () => {
          try {
            const userDocRef = doc(db, "users", user.uid);
            const userDocSnapshot = await getDoc(userDocRef);
            if (userDocSnapshot.exists()) {
              const userData = userDocSnapshot.data();
              const organizationId = userData.organizationId;
              if (organizationId) {
                const organizationDocRef = doc(db, "org", organizationId);
                const unsubscribeOrg = onSnapshot(organizationDocRef, async (organizationDocSnapshot) => {
                  if (organizationDocSnapshot.exists()) {
                    const organizationData = organizationDocSnapshot.data();
                    const staff = organizationData.staff || [];
                    const departments = organizationData.departments || [];

                    const currentUserStaffMember = staff.find((member) => member.userId === user.uid);

                    const staffWithDetails = await Promise.all(
                      staff.map(async (member) => {
                        const staffDocRef = doc(db, "users", member.userId);
                        const staffDocSnapshot = await getDoc(staffDocRef);
                        if (staffDocSnapshot.exists()) {
                          const staffData = staffDocSnapshot.data();
                          return {
                            ...member,
                            email: staffData.email,
                            firstName: staffData.firstName,
                            lastName: staffData.lastName,
                            profilePictureURL: staffData.profilePictureURL,
                          };
                        } else {
                          return member;
                        }
                      })
                    );

                    const filteredStaffMembers = staffWithDetails.filter((member) => {
                      if (currentUserStaffMember && currentUserStaffMember.role === "admin") {
                        return member.department === currentUserStaffMember.department;
                      }
                      return true;
                    });

                    setStaffMembers(filteredStaffMembers);
                    setFilteredStaffMembers(filteredStaffMembers);
                    setAvailableDepartments(departments);
                  } else {
                    console.error("Organization document not found");
                  }
                });

                return unsubscribeOrg;
              } else {
                console.error("User document does not contain organizationId");
              }
            } else {
              console.error("User document not found");
            }
          } catch (error) {
            console.error("Error fetching staff members:", error);
          } finally {
            setIsLoading(false);
          }
        };

        const unsubscribeOrg = await fetchStaffMembers();

        return () => {
          if (unsubscribeOrg) {
            unsubscribeOrg();
          }
        };
      } else {
        setIsLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const filtered = staffMembers.filter((member) => {
      const fullName = `${member.firstName} ${member.lastName}`.toLowerCase();
      const email = member.email.toLowerCase();
      const query = searchQuery.toLowerCase();
      return fullName.includes(query) || email.includes(query);
    });
    setFilteredStaffMembers(filtered);
  }, [searchQuery, staffMembers]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleToggle = async (index) => {
    try {
      const updatedStaffMembers = [...staffMembers];
      updatedStaffMembers[index].verified = !updatedStaffMembers[index].verified;

      const user = auth.currentUser;
      const userDocRef = doc(db, "users", user.uid);
      const userDocSnapshot = await getDoc(userDocRef);
      const userData = userDocSnapshot.data();
      const organizationId = userData.organizationId;
      const organizationDocRef = doc(db, "org", organizationId);
      await updateDoc(organizationDocRef, {
        staff: updatedStaffMembers.map((member) => ({
          userId: member.userId,
          role: member.role,
          verified: member.verified,
          department: member.department,
        })),
      });

      setStaffMembers(updatedStaffMembers);
    } catch (error) {
      console.error("Error updating staff member status:", error);
    }
  };

  const openModal = (staffMember) => {
    setSelectedStaffMember(staffMember);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedStaffMember(null);
    setIsModalOpen(false);
  };

  const handleDepartmentChange = async (newDepartment) => {
    try {
      const updatedStaffMembers = staffMembers.map((member) => {
        if (member.userId === selectedStaffMember.userId) {
          return {
            ...member,
            department: newDepartment,
            verified: false,
          };
        }
        return member;
      });

      const user = auth.currentUser;
      const userDocRef = doc(db, "users", user.uid);
      const userDocSnapshot = await getDoc(userDocRef);
      const userData = userDocSnapshot.data();
      const organizationId = userData.organizationId;
      const organizationDocRef = doc(db, "org", organizationId);
      await updateDoc(organizationDocRef, {
        staff: updatedStaffMembers.map((member) => ({
          userId: member.userId,
          role: member.role,
          verified: member.verified,
          department: member.department,
        })),
      });

      setStaffMembers(updatedStaffMembers);
      closeModal();
    } catch (error) {
      console.error("Error updating staff member department:", error);
    }
  };

  const handleRowClick = (userId) => {
    navigate(`/dashboard/staff/${userId}`);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Box>
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Heading as="h3" size="lg">
          All Staff Members
        </Heading>
        <Input
          placeholder="Search by name or email"
          value={searchQuery}
          onChange={handleSearchChange}
          width="300px"
        />
      </Flex>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Profile Picture</Th>
            <Th>First Name</Th>
            <Th>Last Name</Th>
            <Th>Email</Th>
            <Th>Role</Th>
            <Th>Department</Th>
            <Th>Verified</Th>
          </Tr>
        </Thead>
        <Tbody>
          {filteredStaffMembers.map((member, index) => (
            <Tr
              key={index}
              onClick={() => handleRowClick(member.userId)}
              cursor="pointer"
              _hover={{ backgroundColor: "gray.100" }}
            >
              <Td>
                {member.profilePictureURL ? (
                  <Box
                    width="48px"
                    height="48px"
                    borderRadius="50%"
                    overflow="hidden"
                  >
                    <Image
                      src={member.profilePictureURL}
                      alt="Profile"
                      objectFit="cover"
                      width="100%"
                      height="100%"
                    />
                  </Box>
                ) : (
                  <Avatar
                    name={`${member.firstName} ${member.lastName}`}
                    size="md"
                  />
                )}
              </Td>
              <Td>{member.firstName}</Td>
              <Td>{member.lastName}</Td>
              <Td>{member.email}</Td>
              <Td>{member.role}</Td>
              <Td onClick={(e) => { e.stopPropagation(); openModal(member); }}>
                {member.department}
              </Td>
              <Td>
                <Switch
                  isChecked={member.verified}
                  onChange={(e) => {
                    e.stopPropagation();
                    handleToggle(index);
                  }}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Change Department</ModalHeader>
          <ModalBody>
            <Select
              value={selectedStaffMember?.department || ""}
              onChange={(e) => handleDepartmentChange(e.target.value)}
            >
              {availableDepartments.map((department) => (
                <option key={department} value={department}>
                  {department}
                </option>
              ))}
            </Select>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={closeModal}>
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default StaffAll;