import React, { useEffect, useState } from 'react';
import { Box, Heading, Grid, GridItem, FormControl, Input, Text, Button, Icon, Tag, Wrap, WrapItem } from '@chakra-ui/react';
import { db } from '../../../Firebase';
import { doc, collection, onSnapshot, getDoc, addDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import * as Icons from 'react-icons/fa';
import { useAuth } from '../../../contexts/AuthContext';

const Evaluations = () => {
  const { currentUser, userRole, userDetails } = useAuth();
  const [evaluations, setEvaluations] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser && userDetails.organizationId) {
      fetchEvaluations(currentUser.uid);
    } else {
      setEvaluations([]);
    }
  }, [currentUser, userDetails.organizationId]);

  const fetchEvaluations = async (userId) => {
    let unsubscribe;

    try {
      const userDoc = await getDoc(doc(db, 'users', userId));
      const userData = userDoc.data();
      const organizationId = userData.organizationId;

      if (organizationId) {
        const evaluationsCollection = collection(db, `org/${organizationId}/evaluations`);
        unsubscribe = onSnapshot(evaluationsCollection, (snapshot) => {
          const evaluationsData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
          setEvaluations(evaluationsData);
        });
      }
    } catch (error) {
      console.error('Error fetching evaluations:', error);
    }

    return unsubscribe;
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleTagClick = (tag) => {
    setSelectedTags((prevTags) =>
      prevTags.includes(tag) ? prevTags.filter((t) => t !== tag) : [...prevTags, tag]
    );
  };

  const filteredEvaluations = evaluations.filter((evaluation) =>
    (evaluation.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (evaluation.tags && evaluation.tags.some((tag) => tag.toLowerCase().includes(searchTerm.toLowerCase())))) &&
    (selectedTags.length === 0 || (evaluation.tags && selectedTags.every((tag) => evaluation.tags.includes(tag))))
  );

  const createNewEvaluation = async () => {
    try {
      const organizationId = userDetails.organizationId;
      const newEvaluationRef = await addDoc(collection(db, `org/${organizationId}/evaluations`), {
        name: '',
        description: '',
        tags: [],
        icon: 'FaClipboardList', // Default icon
      });
      navigate(`/dashboard/evaluations/${newEvaluationRef.id}/edit`);
    } catch (error) {
      console.error('Error creating new evaluation:', error);
    }
  };

  const navigateToEditEvaluation = (evaluationId) => {
    navigate(`/dashboard/evaluations/${evaluationId}/edit`);
  };

  const navigateToEvaluationForm = (evaluationId) => {
    navigate(`/dashboard/evaluations/${evaluationId}`);
  };

  const uniqueTags = Array.from(new Set(evaluations.flatMap((evaluation) => evaluation.tags || [])));

  return (
    <Box>
      <Heading fontSize="4xl" fontWeight="bold" mb={4}>
        Evaluations
      </Heading>
      {currentUser ? (
        <>
          <Wrap spacing={4} mb={4}>
            {uniqueTags.map((tag) => (
              <WrapItem key={tag}>
                <Button
                  onClick={() => handleTagClick(tag)}
                  colorScheme={selectedTags.includes(tag) ? 'blue' : 'gray'}
                >
                  {tag}
                </Button>
              </WrapItem>
            ))}
          </Wrap>
          <FormControl mb={4}>
            <Input
              id="search"
              name="search"
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder="Search by evaluation name or tags"
            />
          </FormControl>
          <Grid templateColumns="repeat(4, 1fr)" gap={6}>
            {filteredEvaluations.map((evaluation) => {
              const IconComponent = Icons[evaluation.icon] || Icons.FaQuestionCircle;
              return (
                <GridItem
                  key={evaluation.id}
                  bg="gray.200"
                  p={6}
                  borderRadius="md"
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  alignItems="center"
                  boxShadow="md"
                  minHeight="200px"
                  cursor="pointer"
                  onClick={() => navigateToEvaluationForm(evaluation.id)}
                  position="relative"
                >
                  <Box textAlign="center">
                    <Icon as={IconComponent} boxSize={12} mb={4} />
                    <Heading fontSize="xl" mb={2}>
                      {evaluation.name}
                    </Heading>
                    <Text>{evaluation.description}</Text>
                  </Box>
                  <Box position="absolute" bottom={2} left={2}>
                    {evaluation.tags &&
                      evaluation.tags.map((tag, index) => (
                        <Tag mr={2} key={index} bottom={2} bg="gray.300">
                          {tag}
                        </Tag>
                      ))}
                  </Box>
                  {(userRole === 'admin' || userRole === 'director') && (
                    <Button
                      mt={4}
                      onClick={(e) => {
                        e.stopPropagation();
                        navigateToEditEvaluation(evaluation.id);
                      }}
                      position="absolute"
                      bottom={2}
                      right={2}
                      zIndex={9999}
                      variant="link"
                    >
                      Edit
                    </Button>
                  )}
                </GridItem>
              );
            })}
            {(userRole === 'admin' || userRole === 'director') && (
              <GridItem
                bg="gray.300"
                p={4}
                borderRadius="md"
                cursor="pointer"
                onClick={createNewEvaluation}
                boxShadow="md"
                minHeight="200px"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Text fontWeight="bold">New Evaluation</Text>
              </GridItem>
            )}
          </Grid>
        </>
      ) : (
        <Text>Loading...</Text>
      )}
    </Box>
  );
};

export default Evaluations;
