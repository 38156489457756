import React, { useState, useEffect, useCallback } from 'react';
import {
  VStack,
  Input,
  Button,
  HStack,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Checkbox,
  Select,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import { FaTrash } from 'react-icons/fa';

const MultiSelectEditor = ({ isOpen, onClose, onSave, onDelete, initialData }) => {
  const [label, setLabel] = useState('');
  const [options, setOptions] = useState([{ text: '' }]);
  const [isRequired, setIsRequired] = useState(false);
  const [grading, setGrading] = useState('none');
  const [totalPoints, setTotalPoints] = useState(0);

  const updateOptionsForGrading = useCallback((currentOptions, newGrading) => {
    return currentOptions.map(opt => ({
      text: opt.text,
      value: newGrading === 'passfail' ? 'pass' : (newGrading === 'points' ? 0 : undefined)
    }));
  }, []);

  useEffect(() => {
    if (initialData) {
      setLabel(initialData.label || '');
      setIsRequired(initialData.isRequired || false);
      setGrading(initialData.grading || 'none');
      setTotalPoints(initialData.totalPoints || 0);
      
      const updatedOptions = updateOptionsForGrading(
        initialData.options || [{ text: '' }],
        initialData.grading || 'none'
      );
      setOptions(updatedOptions);
    }
  }, [initialData, updateOptionsForGrading]);

  useEffect(() => {
    setOptions(prevOptions => updateOptionsForGrading(prevOptions, grading));
  }, [grading, updateOptionsForGrading]);

  const handleAddOption = () => {
    setOptions(prevOptions => [...prevOptions, { text: '' }]);
  };

  const handleRemoveOption = (index) => {
    setOptions(prevOptions => prevOptions.filter((_, i) => i !== index));
  };

  const handleOptionChange = (index, field, value) => {
    setOptions(prevOptions => {
      const newOptions = [...prevOptions];
      newOptions[index] = { ...newOptions[index], [field]: value };
      return newOptions;
    });
  };

  const handleGradingChange = (newGrading) => {
    setGrading(newGrading);
  };

  const handleSave = () => {
    const dataToSave = {
      type: 'multi-choice',
      label,
      options: options.filter(option => option.text !== '').map(opt => ({
        text: opt.text,
        ...(grading !== 'none' && { value: opt.value })
      })),
      isRequired,
      grading,
    };
  
    if (grading === 'points') {
      if (!isNaN(totalPoints) && totalPoints >= 0) {
        dataToSave.totalPoints = totalPoints;
      }
    }
  
    onSave(dataToSave);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Multi Select Field</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} align="stretch">
            <FormControl>
              <FormLabel>Field Requirements</FormLabel>
              <Checkbox
                isChecked={isRequired}
                onChange={(e) => setIsRequired(e.target.checked)}
              >
                This field is required
              </Checkbox>
            </FormControl>

            <FormControl>
              <FormLabel>Field Label</FormLabel>
              <Input
                placeholder="Enter field label"
                value={label}
                onChange={(e) => setLabel(e.target.value)}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Grading Type</FormLabel>
              <Select value={grading} onChange={(e) => handleGradingChange(e.target.value)}>
                <option value="none">No Grading</option>
                <option value="passfail">Pass/Fail</option>
                <option value="points">Points</option>
              </Select>
            </FormControl>

            {grading === 'points' && (
              <FormControl>
                <FormLabel>Total Points</FormLabel>
                <NumberInput 
                  value={totalPoints} 
                  onChange={(value) => setTotalPoints(Number(value))}
                  min={0}
                >
                  <NumberInputField placeholder="Total points for this field" />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </FormControl>
            )}

            <FormControl>
              <FormLabel>Options</FormLabel>
              {options.map((option, index) => (
                <HStack key={index} mt={2}>
                  <Input
                    placeholder={`Option ${index + 1}`}
                    value={option.text}
                    onChange={(e) => handleOptionChange(index, 'text', e.target.value)}
                  />
                  {grading === 'passfail' && (
                    <Select
                      value={option.value}
                      onChange={(e) => handleOptionChange(index, 'value', e.target.value)}
                    >
                      <option value="pass">Pass</option>
                      <option value="fail">Fail</option>
                    </Select>
                  )}
                  {grading === 'points' && (
                    <NumberInput
                      value={option.value}
                      onChange={(value) => handleOptionChange(index, 'value', Number(value))}
                      max={totalPoints}
                      min={0}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  )}
                  <IconButton
                    icon={<FaTrash />}
                    onClick={() => handleRemoveOption(index)}
                    aria-label="Remove option"
                  />
                </HStack>
              ))}
              <Button onClick={handleAddOption} mt={2}>Add Option</Button>
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="red" mr={3} onClick={onDelete}>
            Delete
          </Button>
          <Button colorScheme="blue" mr={3} onClick={handleSave}>
            Save
          </Button>
          <Button variant="ghost" onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default MultiSelectEditor;