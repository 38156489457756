import React, { useState, useEffect } from "react";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { Box, Heading, Flex, Avatar, Text, Alert, AlertIcon } from "@chakra-ui/react";
import { db } from "../../../Firebase";
import { useAuth } from "../../../contexts/AuthContext";

function MyTrainee() {
  const { currentUser, userDetails } = useAuth();
  const [trainees, setTrainees] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    let unsubscribeOrg = () => {};

    if (currentUser && userDetails.organizationId) {
      const organizationId = userDetails.organizationId;
      const fetchTrainees = async () => {
        try {
          const organizationDocRef = doc(db, "org", organizationId);
          unsubscribeOrg = onSnapshot(organizationDocRef, async (organizationDocSnapshot) => {
            if (organizationDocSnapshot.exists()) {
              const organizationData = organizationDocSnapshot.data();
              const staff = organizationData.staff || [];
              const trainer = staff.find((member) => member.userId === currentUser.uid && member.role === "trainer");

              if (trainer && Array.isArray(trainer.trainees)) {
                const traineesData = await Promise.all(
                  trainer.trainees.map(async (trainee) => {
                    const traineeDocRef = doc(db, "users", trainee.userId);
                    const traineeDocSnapshot = await getDoc(traineeDocRef);
                    if (traineeDocSnapshot.exists()) {
                      const traineeData = traineeDocSnapshot.data();
                      return {
                        ...trainee,
                        firstName: traineeData.firstName,
                        lastName: traineeData.lastName,
                        profilePictureURL: traineeData.profilePictureURL,
                      };
                    } else {
                      return trainee;
                    }
                  })
                );

                setTrainees(traineesData);
                setError(null);
              } else {
                setError("Current user is not a trainer or has no trainees");
                setTrainees([]);
              }
            } else {
              setError("Organization document not found");
              setTrainees([]);
            }
          });
        } catch (error) {
          setError(`Error fetching trainees: ${error.message}`);
          setTrainees([]);
        }
      };

      fetchTrainees();
    }

    return () => unsubscribeOrg();
  }, [currentUser, userDetails.organizationId]);

  return (
    <Box>
      <Heading as="h3" size="lg" mb={4}>
        My Trainees
      </Heading>
      {error && (
        <Alert status="error" mb={4}>
          <AlertIcon />
          {error}
        </Alert>
      )}
      {trainees.length > 0 ? (
        <Flex direction="column" gap={4}>
          {trainees.map((trainee) => (
            <Flex key={trainee.userId} alignItems="center" p={2} bg="gray.100" borderRadius="md">
              <Avatar
                size="sm"
                src={trainee.profilePictureURL}
                name={`${trainee.firstName} ${trainee.lastName}`}
                mr={2}
              />
              <Text fontWeight="bold">
                {trainee.firstName} {trainee.lastName}
              </Text>
            </Flex>
          ))}
        </Flex>
      ) : (
        <Text>No trainees found.</Text>
      )}
    </Box>
  );
}

export default MyTrainee;