import React, { useState } from 'react';
import { db } from '../../../Firebase';
import { collection, addDoc } from 'firebase/firestore';
import { Button, Input, Textarea, FormControl, FormLabel, Box, Heading } from '@chakra-ui/react';

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccess(false);
    setError('');

    try {
      await addDoc(collection(db, 'messages'), {
        name,
        email,
        message,
        createdAt: new Date()
      });
      setSuccess(true);
      setName('');
      setEmail('');
      setMessage('');
    } catch (err) {
      setError('Failed to send message. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box maxWidth="600px">
        <Heading mb={4}>
            Contact
        </Heading>
      <form onSubmit={handleSubmit}>
        <FormControl isRequired mb={4}>
          <FormLabel>Name</FormLabel>
          <Input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Your Name"
          />
        </FormControl>
        <FormControl isRequired mb={4}>
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Your Email"
          />
        </FormControl>
        <FormControl isRequired mb={4}>
          <FormLabel>Message</FormLabel>
          <Textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Your Message"
            h={200}
          />
        </FormControl>
        {error && <Box color="red.500" mb={4}>{error}</Box>}
        {success && <Box color="green.500" mb={4}>Message sent successfully!</Box>}
        <Button type="submit" colorScheme="blue" isLoading={loading}>
          Submit
        </Button>
      </form>
    </Box>
  );
};

export default Contact;
