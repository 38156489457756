import React, { useState, useEffect, useCallback } from 'react';
import { Box, Heading, List, ListItem, Icon, Text, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Input, useDisclosure } from '@chakra-ui/react';
import { FaFile } from 'react-icons/fa';
import axios from 'axios';

const FileList = ({ vectorStoreId, API_KEY }) => {
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [newFile, setNewFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isFileOpen, onOpen: onFileOpen, onClose: onFileClose } = useDisclosure();

  const fetchFiles = useCallback(async () => {
    try {
      const response = await axios.get(
        `https://api.openai.com/v1/vector_stores/${vectorStoreId}/files`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${API_KEY}`,
            'OpenAI-Beta': 'assistants=v2',
          },
        }
      );
      setFiles(response.data.data);
      console.log('Files:', response.data.data);
    } catch (error) {
      console.error('Error fetching files:', error);
    }
  }, [API_KEY, vectorStoreId]);

  const uploadFile = async () => {
    if (!newFile) return;

    setLoading(true);
    const formData = new FormData();
    formData.append('file', newFile);
    formData.append('purpose', 'assistants');

    try {
      const response = await axios.post(
        `https://api.openai.com/v1/files`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${API_KEY}`,
          },
        }
      );
      const fileId = response.data.id;
      addFileToVectorStore(fileId);
    } catch (error) {
      console.error('Error uploading file:', error);
      setLoading(false);
    }
  };

  const addFileToVectorStore = async (fileId) => {
    try {
      await axios.post(
        `https://api.openai.com/v1/vector_stores/${vectorStoreId}/files`,
        {
          file_id: fileId
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${API_KEY}`,
            'OpenAI-Beta': 'assistants=v2',
          },
        }
      );
      await new Promise(resolve => setTimeout(resolve, 5000));
      fetchFiles();
      onClose();
    } catch (error) {
      console.error('Error adding file to vector store:', error);
    } finally {
      setLoading(false);
    }
  };

  const deleteFile = async (fileId) => {
    setLoading(true);
    try {
      // Delete from vector store
      await axios.delete(
        `https://api.openai.com/v1/vector_stores/${vectorStoreId}/files/${fileId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${API_KEY}`,
            'OpenAI-Beta': 'assistants=v2',
          },
        }
      );
      // Delete from OpenAI
      await axios.delete(
        `https://api.openai.com/v1/files/${fileId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${API_KEY}`,
          },
        }
      );
      await new Promise(resolve => setTimeout(resolve, 1000));
      fetchFiles();
      onFileClose();
    } catch (error) {
      console.error('Error deleting file:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (vectorStoreId) {
      fetchFiles();
    }
  }, [vectorStoreId, fetchFiles]);

  return (
    <Box w="280px" ml={4} border="1px solid" borderColor="#e6e6e6" p={4} overflowY="auto" borderRadius="md">
      <Heading size="md" mb={4}>Protocol Files</Heading>
      <List spacing={3}>
        {files.map(file => (
          <ListItem
            key={file.id}
            display="flex"
            alignItems="center"
            border="1px solid"
            borderColor="#e6e6e6"
            borderRadius="md"
            onClick={() => { setSelectedFile(file); onFileOpen(); }}
            _hover={{ background: 'lightgray', cursor: 'pointer' }}
            p={4}
          >
            <Icon as={FaFile} mr={2} />
            <Text isTruncated>{file.id}</Text>
          </ListItem>
        ))}
      </List>
      <Button onClick={onOpen} colorScheme="blue" mt={4} w="100%" isDisabled={loading}>
        {loading ? 'Please wait...' : 'Add File'}
      </Button>

      {/* Add File Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add a new file</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              type="file"
              onChange={(e) => setNewFile(e.target.files[0])}
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={uploadFile} isDisabled={loading}>
              {loading ? 'Please wait...' : 'Upload File'}
            </Button>
            <Button variant="ghost" onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* File Information Modal */}
      <Modal isOpen={isFileOpen} onClose={onFileClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>File Information</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text><strong>File ID:</strong> {selectedFile?.id}</Text>
            <Text><strong>Created At:</strong> {new Date(selectedFile?.created_at * 1000).toLocaleString()}</Text>
            <Text><strong>Usage Bytes:</strong> {selectedFile?.usage_bytes}</Text>
            <Text><strong>Status:</strong> {selectedFile?.status}</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={() => deleteFile(selectedFile?.id)} isDisabled={loading}>
              {loading ? 'Please wait...' : 'Delete File'}
            </Button>
            <Button variant="ghost" onClick={onFileClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default FileList;
