import React from "react";
import { Box, Text, Input, Textarea, Button, Stack } from "@chakra-ui/react";

const FormField = ({ field, formValues, handleInputChange, handleMultiChoiceSelection, isOptionSelected }) => {
  const renderField = () => {
    switch (field.type) {
      case "single-line":
        return (
          <Input
            placeholder={field.placeholder}
            value={formValues[field.label] || ""}
            onChange={(e) => handleInputChange(field.label, e.target.value)}
            bg="gray.100"
            borderWidth="0px"
          />
        );
      case "multi-line":
        return (
          <Textarea
            placeholder={field.placeholder}
            value={formValues[field.label] || ""}
            onChange={(e) => handleInputChange(field.label, e.target.value)}
            bg="gray.100"
            borderWidth="0px"
          />
        );
      case "multi-choice":
        return (
          <Stack spacing={3} width="100%">
            {field.options.map((option, index) => (
              <Button
                key={index}
                onClick={() => handleMultiChoiceSelection(field, option.text)}
                bg={isOptionSelected(field, option.text) ? "blue.500" : "gray.100"}
                color={isOptionSelected(field, option.text) ? "white" : "black"}
                _hover={{
                  bg: isOptionSelected(field, option.text) ? "blue.600" : "gray.200",
                }}
                justifyContent="flex-start"
                textAlign="left"
                whiteSpace="normal"
                height="auto"
                py={3}
                px={4}
                borderRadius="md"
                w="300px"
                borderWidth="0px"
              >
                {option.text}
              </Button>
            ))}
          </Stack>
        );
      default:
        return null;
    }
  };

  return (
    <Box>
      <Text mb={2} fontWeight="bold">
        {field.label}
        {field.isRequired && <span style={{ color: "red" }}>*</span>}
      </Text>
      {renderField()}
    </Box>
  );
};

export default FormField;